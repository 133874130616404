<div class="spid-notification">
  <div class="message-icon" [ngSwitch]="data.category">
    <div *ngSwitchCase="AppState.error">
      <mat-icon>cancel</mat-icon>
    </div>
    <div *ngSwitchDefault>
      <mat-icon>check_circle</mat-icon>
    </div>
  </div>
  <div class="message">
    <div class="message-category" [ngSwitch]="data.category">
      <div *ngSwitchCase="AppState.error">
        ERROR
      </div>
      <div *ngSwitchDefault>
        SUCCESS
      </div>
    </div>
    <div class="message-text">{{data.message}}</div>
  </div>
</div>
