<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path class="cls-1"
      d="M8.55,29.63A1.25,1.25,0,0,0,9.8,28.38v-2.5H23v2.5a1.25,1.25,0,0,0,2.5,0v-2.5H38.67v2.5a1.25,1.25,0,0,0,2.5,0V24.63a1.25,1.25,0,0,0-1.25-1.25H25.48V19.92a1.25,1.25,0,0,0-2.5,0v3.46H8.55A1.25,1.25,0,0,0,7.3,24.63v3.75a1.25,1.25,0,0,0,1.25,1.25Zm0,0" />
    <rect class="cls-1" x="11.68" y="27.67" width="9.57" height="9.57" rx="2.34" />
    <rect class="cls-1" x="28.02" y="27.67" width="9.57" height="9.57" rx="2.34" />
    <rect class="cls-1" x="11.22" y="11.85" width="9.57" height="9.57" rx="2.34" />
    <path class="cls-1"
      d="M35.25,21.92H30.36a2.85,2.85,0,0,1-2.84-2.85V14.19a2.84,2.84,0,0,1,2.84-2.84h4.89a2.84,2.84,0,0,1,2.84,2.84v4.88A2.85,2.85,0,0,1,35.25,21.92Zm-4.89-9.57a1.84,1.84,0,0,0-1.84,1.84v4.88a1.85,1.85,0,0,0,1.84,1.85h4.89a1.85,1.85,0,0,0,1.84-1.85V14.19a1.84,1.84,0,0,0-1.84-1.84Z" />
  </svg>
</div>
