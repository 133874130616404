import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {

  private errorSubject = new BehaviorSubject<string>('');
  private errorCode = new BehaviorSubject<number>(0);

  public error$ = this.errorSubject.asObservable();
  public errorCode$ = this.errorCode.asObservable();
  constructor() { }

  set(message: string) {
    this.errorSubject.next(message);
  }

  setCode(code: number) {
    this.errorCode.next(code);
  }
}
