<div class="item">
  <label class="label">Company:</label>
  <mat-select [formControl]="companyCtrl" multiple>
    <mat-option disabled *ngIf="companiesFiltered.length === 0">Sorry, no matching options</mat-option>
    <mat-option *ngFor="let c of companiesFiltered" [value]="c.id">
      {{c.name}}
    </mat-option>
  </mat-select>
</div>

<div class="item">
  <label class="label">Organization:</label>
  <mat-select [formControl]="organizationCtrl" multiple>
    <mat-option disabled *ngIf="organizationsFiltered.length === 0">Sorry, no matching options</mat-option>
    <mat-option *ngFor="let o of organizationsFiltered" [value]="o.id">
      {{o.name}}
    </mat-option>
  </mat-select>
</div>

<div class="item">
  <label class="label">Branch:</label>
  <mat-select [formControl]="branchCtrl" multiple>
    <mat-option disabled *ngIf="branchesFiltered.length === 0">Sorry, no matching options</mat-option>
    <mat-option *ngFor="let b of branchesFiltered" [value]="b.id">
      {{b.name}}
    </mat-option>
  </mat-select>
</div>

<div class="item">
  <label class="label">Location:</label>
  <mat-select [formControl]="locationCtrl" multiple>
    <mat-option disabled *ngIf="locationsFiltered.length === 0">Sorry, no matching options</mat-option>
    <mat-option *ngFor="let l of locationsFiltered" [value]="l.id">
      {{l.name}}
    </mat-option>
  </mat-select>
</div>
<ng-content></ng-content>
