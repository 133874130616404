<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path class="cls-1"
      d="M36.45,41.36H11.55A3.37,3.37,0,0,1,8.19,38V20.28a1.17,1.17,0,1,1,2.34,0V38a1,1,0,0,0,1,1h24.9a1,1,0,0,0,1-1V20.28a1.17,1.17,0,1,1,2.34,0V38A3.37,3.37,0,0,1,36.45,41.36Z" />
    <path class="cls-1"
      d="M41.57,24.38a1.18,1.18,0,0,1-.83-.35L26.17,9.46a3.07,3.07,0,0,0-4.34,0L7.26,24A1.17,1.17,0,0,1,5.6,24a1.16,1.16,0,0,1,0-1.65L20.17,7.81a5.41,5.41,0,0,1,7.66,0L42.4,22.38a1.16,1.16,0,0,1,0,1.65,1.18,1.18,0,0,1-.83.35Z" />
    <path class="cls-1"
      d="M23.21,21.08h1.58a.4.4,0,0,0,.4-.41V18.15a.4.4,0,0,0-.4-.4H23.21a.4.4,0,0,0-.4.4v2.52a.4.4,0,0,0,.4.41Z" />
    <path class="cls-1"
      d="M29.21,20.86H26.26v1.49a.53.53,0,0,1-.53.53H22.27a.53.53,0,0,1-.53-.53V20.86H18.79a1.3,1.3,0,0,0-1.3,1.3V35.8a1.3,1.3,0,0,0,1.3,1.3H29.21a1.3,1.3,0,0,0,1.3-1.3V22.16A1.3,1.3,0,0,0,29.21,20.86Zm-.85,8.75L26.8,31.72l0,2.62a.28.28,0,0,1-.1.21.3.3,0,0,1-.15.05l-.08,0L24,33.75l-2.49.83a.24.24,0,0,1-.23,0,.28.28,0,0,1-.1-.21l0-2.62-1.56-2.11a.27.27,0,0,1,0-.23.25.25,0,0,1,.17-.16l2.5-.79,1.52-2.14a.27.27,0,0,1,.42,0l1.52,2.14,2.5.79a.25.25,0,0,1,.17.16A.27.27,0,0,1,28.36,29.61Z" />
  </svg>
</div>
