import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../shared/services/loader.service';
import { catchError, finalize } from 'rxjs/operators';
import { ExceptionService } from '../shared/services/exception.service';
import { CookieService } from 'ngx-cookie-service';
import { indexOf, isPlainObject } from 'lodash';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor(
    private loader: LoaderService,
    private error: ExceptionService,
    private cookie: CookieService,
    private auth: AuthService
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const url = request.url;
    this.totalRequests++;
    this.loader.toggle(true);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');

    if (url.indexOf('dips') !== -1) {
      headers = headers.append('api_key', environment.dipsApiKey);
    } else if (url.indexOf('auth/login') === -1 && url.indexOf('assets') === -1) {
      const token = this.cookie.get('id_token');
      headers = headers.append('Authorization', `Bearer ${token}`);
    }

    const apiReq = request.clone({
      url: url,
      headers: headers,
    });

    return next.handle(apiReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401: // unauthorized
                this.error.set(
                  'Your session has expired. Please log back again to access the system'
                );
                this.error.setCode(401);
                break;
              case 500: //unhandled server error
                this.error.set(
                  'Something went wrong. Please try again. If this issues persists, contact your system admin.'
                );
                break;
              default: // Validation errors, etc
                const err: any = isPlainObject(error.error)
                  ? error.error
                  : JSON.parse(error.error);
                this.error.set(err.Message || err.message);
                break;
            }
          }
        } else {
          this.error.set(
            'Non-http error occured.'
          );
        }
        return throwError(error);
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loader.toggle(false);
        }
      })
    );
  }
}
