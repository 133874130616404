<div class="entity-filter">
  <div>Filter By</div>
  <div class="form-section">
    <label class="label">Company</label>
    <input class="input" type="text" placeholder="Search company" [formControl]="companyCtrl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let company of companies$ | async" [value]="company.name">
        {{company.name}}
      </mat-option>
    </mat-autocomplete>
  </div>
  <div class="form-section" *ngIf="(data.filterLevel === Entity.Organization || data.filterLevel === Entity.Branch) && organizations.length > 0">
    <label class="label">Organization</label>
    <input class="input" type="text" placeholder="Search organization" [formControl]="organizationCtrl" [matAutocomplete]="autoOrg">
    <mat-autocomplete #autoOrg="matAutocomplete">
      <mat-option *ngFor="let org of organizations$ | async" [value]="org.name">
        {{org.name}}
      </mat-option>
    </mat-autocomplete>
  </div>
  <div class="form-section" *ngIf="data.filterLevel === Entity.Branch && branches.length > 0">
    <label class="label">Branch</label>
    <input class="input" type="text" placeholder="Search branch" [formControl]="branchCtrl" [matAutocomplete]="autoBranch">
    <mat-autocomplete #autoBranch="matAutocomplete">
      <mat-option *ngFor="let br of branches$ | async" [value]="br.name">
        {{br.name}}
      </mat-option>
    </mat-autocomplete>
  </div>
  <div mat-dialog-actions class="form-action">
    <button class="btn" mat-dialog-close="false">Cancel</button>
    <button class="btn" (click)="apply()">Apply</button>
  </div>
</div>
