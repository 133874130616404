<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path d="M37.24,23l0,0a0,0,0,0,1,0,0H33.41a.22.22,0,0,0-.22.23v4.2a.22.22,0,0,0,.22.22h0a.22.22,0,0,1,.22.23v2.48a.13.13,0,0,0,0,.06l.57,1.81a.33.33,0,0,1,0,.14L33.7,34a.18.18,0,0,0,0,.16l.63,1.36a.2.2,0,0,1,0,.17l-.64,1.95a.21.21,0,0,0,0,.2l1.31,1.85a.22.22,0,0,0,.28.07h0l0,0h0l0,0h0l1.32-1.85a.24.24,0,0,0,0-.2l-.65-1.95a.25.25,0,0,1,0-.17l.64-1.36h0v-.08a.19.19,0,0,0,0-.08l-.55-1.72a.33.33,0,0,1,0-.14l.56-1.81a.13.13,0,0,0,0-.06V27.77a.23.23,0,0,1,.23-.23h.1l0,0h0l0,0a.41.41,0,0,0,.05-.09h0a.09.09,0,0,0,0-.05h0v-4.2a.3.3,0,0,0,0-.1Z" />
    <path d="M38.66,9.61H31.82a3.07,3.07,0,0,0-3.05,3.45L29.91,22A3.08,3.08,0,0,0,33,24.68h4.55a3.08,3.08,0,0,0,2.23-1A3,3,0,0,0,40.56,22l1.14-8.93a3.06,3.06,0,0,0-3-3.45Zm-7.37,3.63a1,1,0,0,1,1-1h5.88A1,1,0,0,1,38.89,14a1,1,0,0,1-.71.3H32.3A1,1,0,0,1,31.29,13.24Z" />
    <path d="M12.75,16.67v1.76a3.82,3.82,0,0,0,2.43-1.76Z" />
    <path d="M22,20.47a8.38,8.38,0,0,1-4.39-1.2,7.09,7.09,0,0,1-1.42-1.17,5.44,5.44,0,0,1-3.47,2V22A6.13,6.13,0,0,0,25,22V20A8.69,8.69,0,0,1,22,20.47Z" />
    <path d="M17.19,16.67A6.12,6.12,0,0,0,22,18.8a6.85,6.85,0,0,0,3-.66V16.67Z" />
    <rect x="12.06" y="9.81" width="13.52" height="5.19" />
    <path d="M17.2,29.62a7.81,7.81,0,0,1-4.8-3.27A6.47,6.47,0,0,0,6.27,32.8v7.06h8.44V33.1l2.48-3.48Z" />
    <path d="M25.36,26.35a7.85,7.85,0,0,1-5.68,3.41h0l-2.9,4.12v6H31.48V32.8A6.47,6.47,0,0,0,25.36,26.35ZM18.88,39a1,1,0,1,1,1-1A1,1,0,0,1,18.88,39Zm0-3.46a1,1,0,1,1,1-1A1,1,0,0,1,18.88,35.49Z" />
  </svg>
  
</div>