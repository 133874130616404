// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:8081', // NEW ELID API
  apiUrl: 'http://104.128.65.214:5000', // KAMATERA
  // apiUrl: 'https://localhost:5001', // LOCAL DEBUGGING
  // apiUrl: 'http://localhost:26137', // LOCAL DEBUGGING (.NET 6.0)
  dipsUrl: 'http://127.0.0.1:8000/dips/', // DIPS API
  dipsApiKey: '59cdfa81-c289-4a9d-a370-7e0aa196ac0f',
  pageSize: 10
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
