import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { find, indexOf, isEmpty, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Branch } from 'src/app/models/branch';
import { Company } from 'src/app/models/company';
import { Organization } from 'src/app/models/organization';
import { SearchBranchRequest, SearchOrganizationRequest, SearchRequest } from 'src/app/models/search-request';
import { BranchService } from 'src/app/services/branch.service';
import { CompanyService } from 'src/app/services/company.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { _normalizeValue } from 'src/app/utilities/util';

@Component({
  selector: 'app-spid-entity-filter',
  templateUrl: './spid-entity-filter.component.html',
  styleUrls: ['./spid-entity-filter.component.scss']
})
export class SpidEntityFilterComponent implements OnInit {

  companySearchRequest!: SearchRequest;
  companies: Company[] = [];
  companies$!: Observable<Company[]>;
  companyCtrl = new FormControl();

  organizationSearchRequest!: SearchOrganizationRequest;
  organizations: Organization[] = [];
  organizations$!: Observable<Organization[]>;
  organizationCtrl = new FormControl();

  branchSearchRequest!: SearchBranchRequest;
  branches: Branch[] = [];
  branches$!: Observable<Branch[]>;
  branchCtrl = new FormControl();

  Entity = Entity;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FilterData,
    public dialogRef: MatDialogRef<SpidEntityFilterComponent>,
    private companyService: CompanyService,
    private organizationService: OrganizationService,
    private branchService: BranchService) { }

  async ngOnInit(): Promise<void> {
    this.companySearchRequest = {} as SearchRequest;
    await this.getCompanies();

    this.companies$ = this.companyCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterCompanies(value))
    );

    this.companyCtrl.valueChanges
      .pipe(
        filter((data) => !!data && !isEmpty(this.companies))
      )
      .subscribe(async () => {
        const companyId = find(this.companies, c => c.name === this.companyCtrl.value)?.id;
        if (indexOf([Entity.Organization, Entity.Branch], this.data.filterLevel) >= 0 && !isNil(companyId)) {
          this.organizationCtrl.reset();
          await this.getOrganizations(companyId);

          this.organizations$ = this.organizationCtrl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterOrganizations(value))
          );
        }
      });

    this.organizationCtrl.valueChanges
      .pipe(
        filter((data) => !!data && !isEmpty(this.organizations))
      )
      .subscribe(() => {
        const orgId = find(this.organizations, c => c.name === this.organizationCtrl.value)?.id;
        if (this.data.filterLevel === Entity.Branch && !isNil(orgId)) {
          this.branchCtrl.reset();
          this.getBranches(orgId);

          this.branches$ = this.branchCtrl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterBranches(value))
          );
        }
      });
  }
  private _filterBranches(value: any): any {
    const filterValue = _normalizeValue(value);
    return this.branches.filter(c => _normalizeValue(c.name).includes(filterValue));
  }
  private _filterOrganizations(value: any): any {
    const filterValue = _normalizeValue(value);
    return this.organizations.filter(c => _normalizeValue(c.name).includes(filterValue));
  }

  private _filterCompanies(value: any): any {
    const filterValue = _normalizeValue(value);
    return this.companies.filter(c => _normalizeValue(c.name).includes(filterValue));
  }

  async getCompanies() {
    this.companySearchRequest.pageIndex = null;
    this.companySearchRequest.pageSize = null;
    this.companySearchRequest.sortBy = 'name';
    this.companySearchRequest.sortOrder = 'ASC';

    await this.companyService.search(this.companySearchRequest);
    this.companyService.data$.subscribe(data => {
      this.companies = data as Company[];

      if (!isNil(this.data.companyId)) {
        this.companyCtrl.setValue(find(this.companies, c => c.id === this.data.companyId)?.name);
      }
    });
  }

  async getOrganizations(companyId: string) {
    this.organizationSearchRequest = {} as SearchOrganizationRequest;
    this.organizationSearchRequest.pageIndex = null;
    this.organizationSearchRequest.pageSize = null;
    this.organizationSearchRequest.sortBy = 'name';
    this.organizationSearchRequest.sortOrder = 'ASC';
    this.organizationSearchRequest.companyId = companyId;

    await this.organizationService.search(this.organizationSearchRequest);
    this.organizationService.data$.subscribe(data => {
      this.organizations = data as Organization[];
      if (!isNil(this.data.organizationId)) {
        this.organizationCtrl.setValue(find(this.organizations, c => c.id === this.data.organizationId)?.name);
      }
    });
  }

  async getBranches(orgId: string) {
    this.branchSearchRequest = {} as SearchBranchRequest;
    this.branchSearchRequest.pageIndex = null;
    this.branchSearchRequest.pageSize = null;
    this.branchSearchRequest.sortBy = 'name';
    this.branchSearchRequest.sortOrder = 'ASC';
    this.branchSearchRequest.organizationId = orgId;

    await this.branchService.search(this.branchSearchRequest);
    this.branchService.data$.subscribe(data => {
      this.branches = data as Branch[];
      if (!isNil(this.data.branchId)) {
        this.branchCtrl.setValue(find(this.branches, c => c.id === this.data.branchId)?.name);
      }
    });
  }

  apply() {
    this.data.companyId = find(this.companies, c => c.name === this.companyCtrl.value)?.id || '';

    if (indexOf([Entity.Organization, Entity.Branch], this.data.filterLevel) >= 0) {
      this.data.organizationId = find(this.organizations, c => c.name === this.organizationCtrl.value)?.id || '';
    }

    if (this.data.filterLevel === Entity.Branch) {
      this.data.branchId = find(this.branches, c => c.name === this.branchCtrl.value)?.id || '';
    }
    this.dialogRef.close(this.data);
  }
}

export interface FilterData {
  companyId?: string;
  organizationId?: string;
  branchId?: string;
  locationId?: string;
  filterLevel?: Entity
}

export enum Entity {
  Company,
  Organization,
  Branch,
  Location
}