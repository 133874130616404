import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { isEmpty, isNil } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../shared/services/api.service';
import { ExceptionService } from '../shared/services/exception.service';
import { getBasePath } from '../utilities/util';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiService {

  domain = 'auth';

  constructor(
    public http: HttpClient,
    private cookie: CookieService,
    private exception: ExceptionService
  ) {
    super(http);
  }

  async login(userName: string, password: string, closeExistingSession = true) {
    const request = {
      userName,
      password,
      closeExistingSession
    };

    const url = this.host(this.domain, 'login');
    const resp: any = await this.http.post(url, request).pipe(
      catchError((err: any) => {
        this.exception.set(err.error.message)
        throw err;
      })
    ).toPromise();

    const path = getBasePath();
    this.cookie.set('id_token', resp.token, undefined, path);
    sessionStorage.setItem('user', JSON.stringify(resp.response.user));
    sessionStorage.setItem('cashier-cash', JSON.stringify({
      hasOpenCashierCash: resp.response.hasOpenCashierCash,
      cashInDrawer: resp.response.cashInDrawer
    }));
  }

  async logout() {
    const url = this.host(this.domain, 'logout');
    await this.http.post(url, {}).toPromise();
    this.exception.setCode(401);
  }

  hasToken(): boolean {
    return !(isNil(this.cookie.get('id_token')) || isEmpty(this.cookie.get('id_token')))
  }

  public get currentLocation() {
    const token = this.cookie.get('id_token');
    return (jwtDecode(token) as any).locationId;
  }

  public get currentTerminal() {
    const token = this.cookie.get('id_token');
    return (jwtDecode(token) as any).terminalId;
  }
}