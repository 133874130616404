<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1 {
          fill: #000000;
        }

      </style>
    </defs>
    <g id="icon">
      <path class="cls-1" id="Path_37" data-name="Path 37"
        d="M31,24.13a3,3,0,0,1,2-.5l8-8-4.48-4.48-8,8a3.05,3.05,0,0,1-.52,2.05Z" />
      <path class="cls-1" id="Path_38" data-name="Path 38"
        d="M17.11,33.86l-.29-.29-1.51,1.22-2.55,4,.65.66,4-2.55,1.23-1.51-.3-.3L23.44,30l-1.26-1.24Z" />
      <path class="cls-1" id="Path_39" data-name="Path 39"
        d="M24.06,19.77a6.23,6.23,0,0,0-7.63-7.69L20,15.61,19,19.07,15.57,20,12,16.47A6.24,6.24,0,0,0,20,24l0,0L34.53,38.56a2.94,2.94,0,0,0,4.16-4.16ZM36.87,38.06A1.12,1.12,0,1,1,38,36.94a1.11,1.11,0,0,1-1.12,1.12Z" />
    </g>
  </svg>
</div>
