<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1{fill:#000000;}
      </style>
    </defs>
    <path class="cls-1" d="M20.13,24.45A1.4,1.4,0,0,0,18.84,23H15a1.4,1.4,0,0,0-1.29,1.49V34.82h6.44Z" />
    <path class="cls-1" d="M29.16,22.05a1.23,1.23,0,0,0-1.29-1.16H24a1.23,1.23,0,0,0-1.29,1.16V34.82h6.45Z" />
    <path class="cls-1" d="M38.18,19.31a1.18,1.18,0,0,0-1.29-1H33a1.18,1.18,0,0,0-1.29,1V34.82h6.45Z" />
    <path class="cls-1" d="M40.56,36H11.11V11.16A1.23,1.23,0,0,0,9.9,9.91H9.75a1.24,1.24,0,0,0-1.22,1.25v26.2a1.31,1.31,0,0,0,1.29,1.33H40.56a1.23,1.23,0,0,0,1.22-1.26v-.14A1.23,1.23,0,0,0,40.56,36Z" />
    <path class="cls-1" d="M38.18,11.54a.71.71,0,0,0-.71-.71H35.33A.71.71,0,0,0,34.81,12l.66.73L26.94,17.3l-3.42-3.42a.74.74,0,0,0-.79-.15L14,17.52a.71.71,0,0,0,.56,1.31l8.32-3.6,3.45,3.45a.71.71,0,0,0,.84.12l9.31-5,.49.55a.71.71,0,0,0,1.24-.48Z" />
  </svg>  
</div>