<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path class="cls-1" d="M13.48,32.1a.94.94,0,1,0,.94.94A.94.94,0,0,0,13.48,32.1Z" />
    <path class="cls-1" d="M20.79,33.36H15.36a.22.22,0,1,0,0,.44h5.43a.22.22,0,1,0,0-.44Z" />
    <path class="cls-1" d="M20.1,30.16h2a1,1,0,1,0-2,0Z" />
    <path class="cls-1"
      d="M19.65,30.16a1.44,1.44,0,0,1,2.87,0h0a3,3,0,0,1,.43,0c-.27-1.36-1-2.36-1.86-2.36h-6c-.85,0-1.59,1-1.87,2.36a4.23,4.23,0,0,1,.63-.05Z" />
    <path class="cls-1" d="M22.67,32.1a.94.94,0,1,0,.93.94A.94.94,0,0,0,22.67,32.1Z" />
    <path class="cls-1"
      d="M30.14,12.75a5.13,5.13,0,1,0,5.13,5.13A5.13,5.13,0,0,0,30.14,12.75ZM31.72,20a.79.79,0,0,1-.59.25.82.82,0,0,1-.58-.24L29.47,19a.83.83,0,0,1-.24-.58v-3.5a.83.83,0,1,1,1.65,0V18l.84.83A.83.83,0,0,1,31.72,20Z" />
    <path class="cls-1"
      d="M37.29,8.19H10.71a2.51,2.51,0,0,0-2.52,2.52V37.29a2.51,2.51,0,0,0,2.52,2.52H37.29a2.51,2.51,0,0,0,2.52-2.52V10.71A2.51,2.51,0,0,0,37.29,8.19Zm-13,27V37H22V35.91H14.13V37H11.9V35.21A2.92,2.92,0,0,1,11.06,33a2.74,2.74,0,0,1,1.41-2.57c.26-1.9,1.3-3.28,2.59-3.28h6c1.29,0,2.33,1.38,2.59,3.28A2.74,2.74,0,0,1,25.09,33,2.92,2.92,0,0,1,24.25,35.21Zm5.89-10.55a6.77,6.77,0,0,1-5.6-3H17.59a.83.83,0,1,1,0-1.65h6.13a6.87,6.87,0,0,1-.19-3.64H16.22a.83.83,0,1,1,0-1.65h7.92a6.78,6.78,0,1,1,6,9.92Z" />
    <path class="cls-1" d="M20.79,32.54H15.36a.23.23,0,1,0,0,.45h5.43a.22.22,0,0,0,.22-.23A.22.22,0,0,0,20.79,32.54Z" />
  </svg>
</div>
