import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs/operators';
import { ExceptionService } from './shared/services/exception.service';
import { LoaderService } from './shared/services/loader.service';
import { SpidNotificationComponent } from './shared/ui/spid-notification/spid-notification.component';
import { AppState } from './utilities/enumeration';
import { getBasePath } from './utilities/util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'spid10';

  constructor(
    private exception: ExceptionService,
    private snackBar: MatSnackBar,
    public loader: LoaderService,
    private router: Router,
    private cookie: CookieService
  ) {
    this.exception.error$.pipe(filter((data) => !!data))
      .subscribe((message) => this.snackBar.openFromComponent(SpidNotificationComponent, {
        panelClass: [AppState.error],
        data: { message, category: AppState.error }
      }));

    this.exception.errorCode$.subscribe((code) => {
      if (code === 401) {
        this.cookie.delete('id_token', getBasePath());
        this.cookie.delete('id_token', '');
        sessionStorage.clear();
        const path = getBasePath();
        this.router.navigate([path]);
      }
    });

    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      event.returnValue = `You are about to close this browser, leave anyway?`;
    });
  }
}
