import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isEmpty, isNil } from 'lodash';
import { File } from '../../../models/file'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'spid-carousel',
  templateUrl: './spid-carousel.component.html',
  styleUrls: ['./spid-carousel.component.scss']
})
export class SpidCarouselComponent implements OnInit, OnChanges {

  @Input() files: File[] = [];
  selectedFile!: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.initializeFiles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initializeFiles();
  }

  setSelectedFile(file: File) {
    this.files.forEach(f => f.selected = false);
    file.selected = true;
    this.selectedFile = file.safeURL;
  }

  private initializeFiles() {
    if (!isNil(this.files) && !isEmpty(this.files)) {
      this.files.forEach(d => d.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${d.file}`));
      this.setSelectedFile(this.files[0]);
    }
  }

}
