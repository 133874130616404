import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { SpidConfirmDialogComponent } from './ui/spid-confirm-dialog/spid-confirm-dialog.component';
import { SpidSearchComponent } from './ui/spid-search/spid-search.component';
import { SpidSvgComponent } from './ui/spid-svg/spid-svg.component';
import { SpidPaginationComponent } from './ui/spid-pagination/spid-pagination.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SpidNotificationComponent } from './ui/spid-notification/spid-notification.component';
import { MatMenuModule } from '@angular/material/menu';
import { SpidEntityFilterComponent } from './ui/spid-entity-filter/spid-entity-filter.component';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { SpidCompanyDropdownComponent } from './ui/spid-company-dropdown/spid-company-dropdown.component';
import { SpidLocationDropdownComponent } from './ui/spid-location-dropdown/spid-location-dropdown.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTreeModule } from '@angular/material/tree';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { SpidEntityMultipleSelectComponent } from './ui/spid-entity-multiple-select/spid-entity-multiple-select.component';
import { SpidCarouselComponent } from './ui/spid-carousel/spid-carousel.component';
import { SvgUsernameComponent } from './svg/svg-username/svg-username.component';
import { SvgPasswordComponent } from './svg/svg-password/svg-password.component';
import { SvgAdminLogoComponent } from './svg/svg-admin-logo/svg-admin-logo.component';
import { SvgAddComponent } from './svg/svg-add/svg-add.component';
import { SvgClearComponent } from './svg/svg-clear/svg-clear.component';
import { SvgDeleteComponent } from './svg/svg-delete/svg-delete.component';
import { SvgEditComponent } from './svg/svg-edit/svg-edit.component';
import { SvgExcelComponent } from './svg/svg-excel/svg-excel.component';
import { SvgFilterComponent } from './svg/svg-filter/svg-filter.component';
import { SvgPdfComponent } from './svg/svg-pdf/svg-pdf.component';
import { SvgSearchComponent } from './svg/svg-search/svg-search.component';
import { SvgSelectComponent } from './svg/svg-select/svg-select.component';
import { SvgViewComponent } from './svg/svg-view/svg-view.component';
import { SvgVoidComponent } from './svg/svg-void/svg-void.component';
import { SvgArrowRightComponent } from './svg/svg-arrow-right/svg-arrow-right.component';
import { SvgCloseComponent } from './svg/svg-close/svg-close.component';
import { SvgDownComponent } from './svg/svg-down/svg-down.component';
import { SvgFullscreenComponent } from './svg/svg-fullscreen/svg-fullscreen.component';
import { SvgLeftComponent } from './svg/svg-left/svg-left.component';
import { SvgLockComponent } from './svg/svg-lock/svg-lock.component';
import { SvgMenuComponent } from './svg/svg-menu/svg-menu.component';
import { SvgMismatchComponent } from './svg/svg-mismatch/svg-mismatch.component';
import { SvgNextComponent } from './svg/svg-next/svg-next.component';
import { SvgPreviousComponent } from './svg/svg-previous/svg-previous.component';
import { SvgRefundComponent } from './svg/svg-refund/svg-refund.component';
import { SvgEntityComponent } from './svg/svg-entity/svg-entity.component';
import { SvgF1VehicleInComponent } from './svg/svg-f1-vehicle-in/svg-f1-vehicle-in.component';
import { SvgF2VehicleOutComponent } from './svg/svg-f2-vehicle-out/svg-f2-vehicle-out.component';
import { SvgF3GuestParkingComponent } from './svg/svg-f3-guest-parking/svg-f3-guest-parking.component';
import { SvgF5FixedRateComponent } from './svg/svg-f5-fixed-rate/svg-f5-fixed-rate.component';
import { SvgF6DiscountComponent } from './svg/svg-f6-discount/svg-f6-discount.component';
import { SvgF7LostCardComponent } from './svg/svg-f7-lost-card/svg-f7-lost-card.component';
import { SvgF8FocComponent } from './svg/svg-f8-foc/svg-f8-foc.component';
import { SvgF9AdvancePaymentComponent } from './svg/svg-f9-advance-payment/svg-f9-advance-payment.component';
import { SvgF10SearchVehicleComponent } from './svg/svg-f10-search-vehicle/svg-f10-search-vehicle.component';
import { SvgF12BarrierOverrideComponent } from './svg/svg-f12-barrier-override/svg-f12-barrier-override.component';
import { SvgGuestParkingComponent } from './svg/svg-guest-parking/svg-guest-parking.component';
import { SvgManagementComponent } from './svg/svg-management/svg-management.component';
import { SvgNotificationComponent } from './svg/svg-notification/svg-notification.component';
import { SvgParkerTypeComponent } from './svg/svg-parker-type/svg-parker-type.component';
import { SvgRatesComponent } from './svg/svg-rates/svg-rates.component';
import { SvgSlotComponent } from './svg/svg-slot/svg-slot.component';
import { SvgTerminalComponent } from './svg/svg-terminal/svg-terminal.component';
import { SvgUtilitiesComponent } from './svg/svg-utilities/svg-utilities.component';
import { SvgCashOutComponent } from './svg/svg-cash-out/svg-cash-out.component';
import { SvgClosedCashComponent } from './svg/svg-closed-cash/svg-closed-cash.component';
import { SvgMyProfileComponent } from './svg/svg-my-profile/svg-my-profile.component';
import { SvgDashboardComponent } from './svg/svg-dashboard/svg-dashboard.component';
import { SvgForPaymentComponent } from './svg/svg-for-payment/svg-for-payment.component';
import { SvgReportsComponent } from './svg/svg-reports/svg-reports.component';
import { SvgTransactionComponent } from './svg/svg-transaction/svg-transaction.component';
import { SvgPosSettingsComponent } from './svg/svg-pos-settings/svg-pos-settings.component';
import { SvgAdminSettingsComponent } from './svg/svg-admin-settings/svg-admin-settings.component';
import { SvgGuestsInsideComponent } from './svg/svg-guests-inside/svg-guests-inside.component';
import { SvgOverstayingComponent } from './svg/svg-overstaying/svg-overstaying.component';
import { SvgSlotsAvailableComponent } from './svg/svg-slots-available/svg-slots-available.component';
import { SvgVehiclesForTaggingComponent } from './svg/svg-vehicles-for-tagging/svg-vehicles-for-tagging.component';
import { SvgVehiclesInsideComponent } from './svg/svg-vehicles-inside/svg-vehicles-inside.component';
import { SvgVipInsideComponent } from './svg/svg-vip-inside/svg-vip-inside.component';
import { SvgF4ValetServiceComponent } from './svg/svg-f4-valet-service/svg-f4-valet-service.component';
import { SvgCashInComponent } from './svg/svg-cash-in/svg-cash-in.component';
import { SvgPosLogoComponent } from './svg/svg-pos-logo/svg-pos-logo.component';
import { SvgIconDirective } from './ui/svg-icon.directive';
@NgModule({
  declarations: [
    SpidSearchComponent,
    SpidSvgComponent,
    SpidPaginationComponent,
    SpidConfirmDialogComponent,
    SpidNotificationComponent,
    SpidEntityFilterComponent,
    SpidCompanyDropdownComponent,
    SpidLocationDropdownComponent,
    SpidEntityMultipleSelectComponent,
    SpidCarouselComponent,
    SvgUsernameComponent,
    SvgPasswordComponent,
    SvgAdminLogoComponent,
    SvgAddComponent,
    SvgClearComponent,
    SvgDeleteComponent,
    SvgEditComponent,
    SvgExcelComponent,
    SvgFilterComponent,
    SvgPdfComponent,
    SvgSearchComponent,
    SvgSelectComponent,
    SvgViewComponent,
    SvgVoidComponent,
    SvgArrowRightComponent,
    SvgCloseComponent,
    SvgDownComponent,
    SvgFullscreenComponent,
    SvgLeftComponent,
    SvgLockComponent,
    SvgMenuComponent,
    SvgMismatchComponent,
    SvgNextComponent,
    SvgPreviousComponent,
    SvgRefundComponent,
    SvgEntityComponent,
    SvgF1VehicleInComponent,
    SvgF2VehicleOutComponent,
    SvgF3GuestParkingComponent,
    SvgF5FixedRateComponent,
    SvgF6DiscountComponent,
    SvgF7LostCardComponent,
    SvgF8FocComponent,
    SvgF9AdvancePaymentComponent,
    SvgF10SearchVehicleComponent,
    SvgF12BarrierOverrideComponent,
    SvgGuestParkingComponent,
    SvgManagementComponent,
    SvgNotificationComponent,
    SvgParkerTypeComponent,
    SvgRatesComponent,
    SvgSlotComponent,
    SvgTerminalComponent,
    SvgUtilitiesComponent,
    SvgCashOutComponent,
    SvgClosedCashComponent,
    SvgMyProfileComponent,
    SvgDashboardComponent,
    SvgForPaymentComponent,
    SvgReportsComponent,
    SvgTransactionComponent,
    SvgPosSettingsComponent,
    SvgAdminSettingsComponent,
    SvgGuestsInsideComponent,
    SvgOverstayingComponent,
    SvgSlotsAvailableComponent,
    SvgVehiclesForTaggingComponent,
    SvgVehiclesInsideComponent,
    SvgVipInsideComponent,
    SvgF4ValetServiceComponent,
    SvgCashInComponent,
    SvgPosLogoComponent,
    SvgIconDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatSidenavModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressBarModule,
    MatSortModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatMenuModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTreeModule,
    MatRadioModule,
    MatBadgeModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    SpidSearchComponent,
    SpidSvgComponent,
    SpidPaginationComponent,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatSidenavModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressBarModule,
    MatSortModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatMenuModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatSelectModule,
    SpidCompanyDropdownComponent,
    SpidLocationDropdownComponent,
    SpidEntityMultipleSelectComponent,
    MatDatepickerModule,
    MatTreeModule,
    MatRadioModule,
    MatBadgeModule,
    SpidCarouselComponent,
    SvgUsernameComponent,
    SvgPasswordComponent,
    SvgAdminLogoComponent,
    SvgAddComponent,
    SvgClearComponent,
    SvgDeleteComponent,
    SvgEditComponent,
    SvgExcelComponent,
    SvgFilterComponent,
    SvgPdfComponent,
    SvgSearchComponent,
    SvgSelectComponent,
    SvgViewComponent,
    SvgVoidComponent,
    SvgArrowRightComponent,
    SvgCloseComponent,
    SvgDownComponent,
    SvgFullscreenComponent,
    SvgLeftComponent,
    SvgLockComponent,
    SvgMenuComponent,
    SvgMismatchComponent,
    SvgNextComponent,
    SvgPreviousComponent,
    SvgRefundComponent,
    SvgEntityComponent,
    SvgF1VehicleInComponent,
    SvgF2VehicleOutComponent,
    SvgF3GuestParkingComponent,
    SvgF5FixedRateComponent,
    SvgF6DiscountComponent,
    SvgF7LostCardComponent,
    SvgF8FocComponent,
    SvgF9AdvancePaymentComponent,
    SvgF10SearchVehicleComponent,
    SvgF12BarrierOverrideComponent,
    SvgGuestParkingComponent,
    SvgManagementComponent,
    SvgNotificationComponent,
    SvgParkerTypeComponent,
    SvgRatesComponent,
    SvgSlotComponent,
    SvgTerminalComponent,
    SvgUtilitiesComponent,
    SvgCashOutComponent,
    SvgClosedCashComponent,
    SvgMyProfileComponent,
    SvgDashboardComponent,
    SvgForPaymentComponent,
    SvgReportsComponent,
    SvgTransactionComponent,
    SvgPosSettingsComponent,
    SvgAdminSettingsComponent,
    SvgGuestsInsideComponent,
    SvgOverstayingComponent,
    SvgSlotsAvailableComponent,
    SvgVehiclesForTaggingComponent,
    SvgVehiclesInsideComponent,
    SvgVipInsideComponent,
    SvgF4ValetServiceComponent,
    SvgCashInComponent,
    SvgPosLogoComponent
  ]
})
export class SharedModule { }
