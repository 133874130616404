import { Component, OnInit } from '@angular/core';
import { clone, isEmpty, isNil, sortBy } from 'lodash';
import { forkJoin } from 'rxjs';
import { Location } from 'src/app/models/location';
import { Branch } from 'src/app/models/branch';
import { Company } from 'src/app/models/company';
import { Organization } from 'src/app/models/organization';
import { BranchService } from 'src/app/services/branch.service';
import { CompanyService } from 'src/app/services/company.service';
import { LocationService } from 'src/app/services/location.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { FormControl } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[spid-entity-multiple-select]',
  templateUrl: './spid-entity-multiple-select.component.html',
  styleUrls: ['./spid-entity-multiple-select.component.scss']
})
export class SpidEntityMultipleSelectComponent implements OnInit {

  companies: Company[] = [];
  organizations: Organization[] = [];
  branches: Branch[] = [];
  locations: Location[] = [];

  companiesFiltered: Company[] = [];
  organizationsFiltered: Organization[] = [];
  branchesFiltered: Branch[] = [];
  locationsFiltered: Location[] = [];

  companyCtrl = new FormControl();
  organizationCtrl = new FormControl();
  branchCtrl = new FormControl();
  locationCtrl = new FormControl();

  constructor(
    private companyService: CompanyService,
    private organizationService: OrganizationService,
    private branchService: BranchService,
    private locationService: LocationService,
  ) { }

  async ngOnInit(): Promise<void> {

    this.populateEntities();
    this.listenToChanges();

    return;
  }

  private populateEntities() {
    forkJoin([
      this.companyService.getAllActive(),
      this.organizationService.getAllActive(),
      this.branchService.getAllActive(),
      this.locationService.getAllActive()
    ]).subscribe((data: any[]) => {
      this.companies = sortBy(data[0] as Company[], (x) => x.name.toLocaleLowerCase());
      this.organizations = sortBy(data[1] as Organization[], (x) => x.name.toLocaleLowerCase());
      this.branches = sortBy(data[2] as Branch[], (x) => x.name.toLocaleLowerCase());
      this.locations = sortBy(data[3] as Location[], (x) => x.name.toLocaleLowerCase());

      this.companiesFiltered = clone(this.companies);
      this.organizationsFiltered = clone(this.organizations);
      this.branchesFiltered = clone(this.branches);
      this.locationsFiltered = clone(this.locations);
    });
  }

  private listenToChanges() {
    this.companyCtrl.valueChanges.subscribe((companyIds: string[]) => {
      this.organizationCtrl.reset();
      this.branchCtrl.reset();
      this.locationCtrl.reset();

      if (isNil(companyIds)) {
        return;
      }

      if (isEmpty(companyIds)) {
        this.companiesFiltered = clone(this.companies);
        this.organizationsFiltered = clone(this.organizations);
        this.branchesFiltered = clone(this.branches);
        this.locationsFiltered = clone(this.locations);
      } else {
        this.organizationsFiltered = clone(this.organizations).filter(x => companyIds.indexOf(x.companyId) >= 0);
        this.branchesFiltered = clone(this.branches).filter(x => companyIds.indexOf(x.companyId) >= 0);
        this.locationsFiltered = clone(this.locations).filter(x => companyIds.indexOf(x.companyId) >= 0);
      }
    });

    this.organizationCtrl.valueChanges.subscribe((organizationIds) => {
      this.branchCtrl.reset();
      this.locationCtrl.reset();

      if (isNil(organizationIds)) {
        return;
      }

      this.branchesFiltered = clone(this.branches).filter(x => organizationIds.indexOf(x.organizationId) >= 0);
      this.locationsFiltered = clone(this.locations).filter(x => organizationIds.indexOf(x.organizationId) >= 0);
    });

    this.branchCtrl.valueChanges.subscribe((branchIds) => {
      if (isNil(branchIds)) {
        return;
      }
      this.locationCtrl.reset();
      this.locationsFiltered = clone(this.locations).filter(x => branchIds.indexOf(x.branchId) >= 0);
    });
  }
}
