<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
    <g>
      <path d="M39.5,35.3l-2.8-2.6c-0.4-0.4-1-0.7-1.6-0.8c4-5.8,3.3-13.7-1.6-18.7v0c-5.7-5.7-15-5.7-20.7,0c-5.7,5.7-5.7,15,0,20.7
		c5,5,12.9,5.7,18.7,1.7c0.1,0.6,0.4,1.1,0.8,1.6l2.8,2.6c1.2,1.2,3.2,1.2,4.4,0C40.8,38.5,40.8,36.6,39.5,35.3z M31.7,32.2
		c-4.7,4.7-12.4,4.7-17.1,0c-4.7-4.7-4.7-12.4,0-17.1c4.7-4.7,12.4-4.7,17.1,0C36.4,19.8,36.4,27.5,31.7,32.2z" />
      <path d="M27.7,29.2c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-1h-2.5V29.2z" />
      <path d="M16.5,29.2c0,0.7,0.6,1.2,1.3,1.2c0.6,0,1.1-0.5,1.2-1.1v-0.1v-1h-2.5V29.2z" />
      <path d="M30,21l-1.3-3.5c-0.3-0.8-1-1.3-1.8-1.2h-7c-0.8,0-1.6,0.5-1.8,1.2L16.6,21c-0.9,0.2-1.5,1-1.5,1.9v3.4c0,0.6,0.4,1,1,1
		h14.3c0.6,0,1-0.4,1-1c0,0,0,0,0,0v-3.4C31.5,22,30.9,21.2,30,21z M17.9,24.8c-0.6-0.1-1.1-0.6-1.1-1.3c0.1-0.6,0.5-1,1.1-1.1
		c0.6,0,1.1,0.6,1.1,1.2C19,24.3,18.5,24.7,17.9,24.8z M19.1,21c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.1,0-0.2l0.7-1.7
		c0.1-0.4,0.4-0.6,0.8-0.7h6.4c0.5,0,0.6,0.2,0.8,0.7l0.8,1.6c0.1,0.3,0,0.6-0.3,0.7c-0.1,0-0.1,0-0.2,0L23.3,21H19.1z M29.8,23.8
		c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2C29.3,22.6,29.8,23.1,29.8,23.8
		C29.8,23.8,29.8,23.8,29.8,23.8z" />
      <path
        d="M25.7,19.2c-0.8,0-1.5,0.7-1.5,1.5H25c0.1-0.3,0.4-0.6,0.7-0.6c0.4,0,0.7,0.3,0.7,0.6h0.8C27.2,19.9,26.5,19.2,25.7,19.2z" />
    </g>
  </svg>

</div>
