<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path id="Path_13" data-name="Path 13" class="cls-1"
      d="M19.62,13.09H13.74V19a1.18,1.18,0,0,1-2.36,0V13.09H5.5A1.17,1.17,0,0,1,4.29,12a1.19,1.19,0,0,1,1.15-1.21h6V4.86a1.18,1.18,0,1,1,2.35-.06v5.94h5.88A1.18,1.18,0,0,1,20.77,12a1.17,1.17,0,0,1-1.15,1.14Z" />
  </svg>
</div>
