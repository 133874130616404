/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'spid-pagination',
  templateUrl: './spid-pagination.component.html',
  styleUrls: ['./spid-pagination.component.scss']
})
export class SpidPaginationComponent implements OnInit, OnChanges {

  constructor() { }

  hasPrevious: boolean = false;
  hasNext: boolean = false;
  currentPage: number = 0;

  @Input() recordCount: number = 0;
  @Input() recordsPerPage: number = 10;
  @Output() pageClicked = new EventEmitter();

  ngOnInit(): void {
    this.validate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.validate();
  }

  validate() {
    this.hasNext = this.currentPage < Math.floor(this.recordCount / this.recordsPerPage);
    this.hasPrevious = this.currentPage > 0;
  }

  hasMoreThanOnePage() {
    return Math.ceil(this.recordCount / this.recordsPerPage) > 1;
  }

  onPrevious() {
    if (this.hasPrevious) {
      this.currentPage--;
      this.validate();
      this.pageClicked.emit(this.currentPage);
    }
  }
  onNext() {
    if (this.hasNext) {
      this.currentPage++;
      this.validate();
      this.pageClicked.emit(this.currentPage);
    }
  }
}
