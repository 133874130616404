/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { chain, isEmpty, last } from 'lodash';
import { Subscription } from 'rxjs';
import { SpidSvgService } from 'src/app/shared/services/spid-svg-service';

@Component({
  selector: 'spid-svg',
  templateUrl: './spid-svg.component.html',
  styleUrls: ['./spid-svg.component.scss']
})
export class SpidSvgComponent implements OnChanges, OnDestroy {

  @Input() src!: string;
  @Input() clickable = true;
  @Input() disabled = false;
  @Input() toolTipText = '';
  svg: any;

  private svgSubscription$: Subscription = new Subscription();

  constructor(
    public sanitizer: DomSanitizer,
    public svgIconService: SpidSvgService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.toolTipText = !isEmpty(this.toolTipText) ? this.toolTipText :
      chain(this.src).split('/').last().split('.').first().value() || '';

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && propName === 'src') {
        this.initializeSVG();
      }
    }
  }

  ngOnDestroy(): void {
    this.svgSubscription$.unsubscribe();
  }

  initializeSVG = (): void => {
    const path = this.src ? this.src.replace(/\.[^/.]+$/, '') + '.svg' : '';
    const svg = this.svgIconService.getSvg(path);
    this.svgSubscription$ = svg.subscribe((data) => {
      this.svg = this.sanitizer.bypassSecurityTrustHtml(data.toString());
    });
  }

}

