import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { compact } from 'lodash';
import { BehaviorSubject, noop, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { BaseModel } from 'src/app/models/base-model';
import { SearchRequest } from 'src/app/models/search-request';
import { SearchResponse } from 'src/app/models/search-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService implements IApiService<BaseModel> {
  domain = 'companies';
  protected dataSubject = new BehaviorSubject<BaseModel[]>([]);
  protected countSubject = new BehaviorSubject<number>(0);

  public data$ = this.dataSubject.asObservable();
  public count$ = this.countSubject.asObservable();

  constructor(protected http: HttpClient) {}

  get(): Observable<BaseModel[]> {
    const url = this.host(this.domain);
    return this.http.get<BaseModel[]>(url);
  }

  getAllActive(): Observable<BaseModel[]> {
    const url = this.host(this.domain, 'active');
    return this.http.get<BaseModel[]>(url);
  }

  getById(id: string): Observable<BaseModel> {
    const url = this.host(`${this.domain}/id/${id}`);
    return this.http.get<BaseModel>(url);
  }

  search(search: SearchRequest): Subscription {
    const url = this.host(`${this.domain}/search`);
    return this.http
      .post<SearchResponse<BaseModel>>(url, search)
      .pipe(
        catchError(() => of({ count: 0, records: [] })),
        finalize(() => noop)
      )
      .subscribe((data) => {
        this.dataSubject.next(data.records);
        this.countSubject.next(data.count);
      });
  }

  post(request: BaseModel): Observable<any> {
    request.active = true;
    const url = this.host(this.domain);
    return this.http.post(url, request);
  }

  put(request: BaseModel): Observable<any> {
    request.active = request.active ?? true;
    const url = this.host(`${this.domain}?id=${request.id}`);
    return this.http.put(url, request);
  }

  delete(id: string): Observable<any> {
    const url = this.host(`${this.domain}/delete?id=${id}`);
    return this.http.delete(url);
  }

  protected getOptions(options?: any): void {
    return Object.assign(this.defaultHttpHeaders, options);
  }

  protected get defaultHttpHeaders(): any {
    return {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
  }

  protected host(...args: any[]): string {
    const fragments = compact(args);
    return `${environment.apiUrl}/api/${fragments.join('/')}`;
  }
}

export interface IApiService<BaseModel> {
  get(): Observable<BaseModel[]>;

  getById(id: string): Observable<BaseModel>;

  search(search: SearchRequest): Subscription;

  post(request: BaseModel): Observable<any>;

  put(request: BaseModel): Observable<any>;

  delete(id: string): Observable<any>;
}
