<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
    <g>
      <path class="cls-1" d="M16.4,27.1c0.2,0.1,0.4,0.1,0.6,0.1h14.5c0.2,0,0.3,0,0.5-0.1c0.5-0.2,0.8-0.8,0.5-1.3c-0.1-0.2-0.3-0.4-0.5-0.5
		c-0.2,0-0.4-0.1-0.6-0.1H17c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.7,0.5-0.7,0.9C15.7,26.6,16,27,16.4,27.1z" />
      <path class="cls-1" d="M31.7,30C31.7,30,31.7,30,31.7,30L17,30c-0.1,0-0.3,0-0.4,0c-0.5,0.2-0.8,0.8-0.6,1.3c0.1,0.3,0.4,0.5,0.7,0.6
		c0.1,0,0.3,0,0.4,0h14.5c0.1,0,0.3,0,0.4,0c0.5-0.1,0.8-0.5,0.8-1C32.7,30.3,32.2,29.9,31.7,30z" />
      <path class="cls-1" d="M16.6,22.4c0.1,0,0.2,0,0.4,0h7.5c0.1,0,0.2,0,0.4,0c0.5-0.1,0.8-0.6,0.8-1.1c-0.1-0.5-0.5-0.9-1.1-0.8h-7.8
		c-0.5,0-0.9,0.4-0.9,0.9C15.8,21.9,16.1,22.3,16.6,22.4z" />
      <path class="cls-1" d="M38.1,7.9C38.1,7.9,38.1,7.9,38.1,7.9L10,7.9c-1.3,0-2.3,1-2.3,2.3c0,0,0,0,0,0v3.1c0,1.3,1,2.3,2.3,2.3c0,0,0,0,0,0h0.7
		V38c0,0.9,0.5,1.7,1.3,2c0.8,0.4,1.8,0.2,2.5-0.4l0.8-0.7l0.9-0.7l2.5,2c0.4,0.4,1,0.6,1.6,0.6c0.6,0,1.2-0.3,1.6-0.6l0.8-0.7
		c0.5-0.4,1.1-0.9,1.6-1.3l1.6,1.3l0.8,0.7c0.9,0.9,2.3,0.9,3.2,0l0.3-0.2l2.2-1.8l0.9,0.7l0.8,0.7c0.7,0.6,1.6,0.8,2.5,0.4
		c0.8-0.3,1.4-1.1,1.3-2V15.7h0.3c1.3,0,2.3-1,2.3-2.3c0,0,0,0,0,0v-3.1C40.4,9,39.4,7.9,38.1,7.9z M35.6,26.4V37
		c0,0.2-0.1,0.5-0.3,0.5c-0.3,0.1-0.6,0.1-0.8-0.2l-0.8-0.6l-1-0.8c-0.4-0.4-1.1-0.4-1.5,0c-0.7,0.6-1.5,1.2-2.3,1.9L28.7,38
		c-0.3,0.3-0.7,0.4-1.1,0.1c0,0-0.1-0.1-0.1-0.1l-0.8-0.6l-1.8-1.4c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3l-1.7,1.4
		L20.9,38c-0.3,0.3-0.7,0.4-1,0.1c0,0-0.1-0.1-0.1-0.1c-0.9-0.7-1.7-1.4-2.5-2.1c-0.4-0.4-1.1-0.4-1.5,0l-1,0.8l-0.8,0.7
		c-0.2,0.2-0.5,0.3-0.8,0.2c-0.2-0.1-0.3-0.2-0.3-0.6V15.7h22.8V26.4z" />
    </g>
  </svg>
</div>
