<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path class="cls-1"
      d="M36.45,41.36H11.55A3.37,3.37,0,0,1,8.19,38V20.28a1.17,1.17,0,1,1,2.34,0V38a1,1,0,0,0,1,1h24.9a1,1,0,0,0,1-1V20.28a1.17,1.17,0,1,1,2.34,0V38A3.37,3.37,0,0,1,36.45,41.36Z" />
    <path class="cls-1"
      d="M41.57,24.38a1.18,1.18,0,0,1-.83-.35L26.17,9.46a3.07,3.07,0,0,0-4.34,0L7.26,24A1.17,1.17,0,0,1,5.6,24a1.16,1.16,0,0,1,0-1.65L20.17,7.81a5.41,5.41,0,0,1,7.66,0L42.4,22.38a1.16,1.16,0,0,1,0,1.65,1.18,1.18,0,0,1-.83.35Z" />
    <path class="cls-1"
      d="M30.71,29.59H29.25a3.78,3.78,0,0,1,.23,1.3v5.52a1.4,1.4,0,0,1-.1.54H31.8a1.63,1.63,0,0,0,1.63-1.63v-3A2.72,2.72,0,0,0,30.71,29.59Z" />
    <path class="cls-1"
      d="M18.52,30.9a3.8,3.8,0,0,1,.23-1.31H17.29a2.72,2.72,0,0,0-2.72,2.72v3A1.63,1.63,0,0,0,16.2,37h2.42a1.4,1.4,0,0,1-.1-.54Z" />
    <path class="cls-1"
      d="M25.67,28.17H22.33a2.72,2.72,0,0,0-2.72,2.73v5.51a.54.54,0,0,0,.54.54h7.7a.54.54,0,0,0,.54-.54V30.9A2.72,2.72,0,0,0,25.67,28.17Z" />
    <path class="cls-1" d="M24,21a3.28,3.28,0,0,0-1.6,6.13,3.26,3.26,0,0,0,3.2,0A3.28,3.28,0,0,0,24,21Z" />
    <path class="cls-1" d="M18.25,24a2.45,2.45,0,1,0,2.16,3.59,2.34,2.34,0,0,0,.29-1.15A2.45,2.45,0,0,0,18.25,24Z" />
    <path class="cls-1" d="M29.75,24a2.45,2.45,0,0,0-2.45,2.44,2.34,2.34,0,0,0,.29,1.15A2.45,2.45,0,1,0,29.75,24Z" />
  </svg>
</div>
