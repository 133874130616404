<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1 {
          fill: #000000;
        }

      </style>
    </defs>
    <g id="Group_31" data-name="Group 31">
      <g id="Group_12" data-name="Group 12">
        <ellipse class="cls-1" id="Ellipse_18" data-name="Ellipse 18" cx="22.65" cy="16.98" rx="7.54" ry="7.07" />
      </g>
      <g id="Group_13" data-name="Group 13">
        <path id="Path_29" data-name="Path 29" class="cls-1"
          d="M23.06,34a6.24,6.24,0,0,0-6.18,5.09H29.24A6.26,6.26,0,0,0,23.06,34Z" />
        <path id="Path_30" data-name="Path 30" class="cls-1"
          d="M8.51,33.68v3.63a2,2,0,0,0,1.82,1.82h4a8.87,8.87,0,0,1,4-6.36l-3.09-4.91C11.6,29,8.51,31,8.51,33.68Z" />
        <path id="Path_31" data-name="Path 31" class="cls-1"
          d="M23.06,26.4a23.27,23.27,0,0,0-5.82.73l2.91,4.73a7.6,7.6,0,0,1,2.91-.55A8.68,8.68,0,0,1,31.78,39h4a2,2,0,0,0,1.82-1.82V33.49C37.6,28.77,28,26.4,23.06,26.4Z" />
      </g>
    </g>
  </svg>
</div>
