import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class BranchService extends ApiService {

  domain = 'branches';

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }
}
