<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path id="Path_17" data-name="Path 17" class="cls-1"
      d="M12.55,6.14a9.89,9.89,0,0,0-9.21,6.28,9.9,9.9,0,0,0,18.43,0A9.9,9.9,0,0,0,12.55,6.14Zm0,10.47a4.19,4.19,0,1,1,4.19-4.19A4.2,4.2,0,0,1,12.55,16.61Zm0-6.7a2.51,2.51,0,1,0,2.52,2.51A2.51,2.51,0,0,0,12.55,9.91Z" />
  </svg>
</div>
