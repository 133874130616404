<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path id="Path_19" data-name="Path 19" class="cls-1"
      d="M5.29,16.16V18.7a.42.42,0,0,0,.42.42H8.25a.42.42,0,0,0,.3-.12L17.7,9.86,14.55,6.71,5.41,15.85A.43.43,0,0,0,5.29,16.16ZM20.13,7.43a.86.86,0,0,0,0-1.19l-2-2a.86.86,0,0,0-1.19,0L15.45,5.82,18.59,9Z" />
  </svg>
</div>
