<div class="spid-carousel">
  <div class="file-preview" *ngIf="selectedFile">
    <img class="image-preview" [src]="selectedFile" />
  </div>
  <div class="file-pagination">
    <div *ngFor="let file of files" (click)="setSelectedFile(file)">
      <img class="thumbnails" [alt]="file.fileName" [src]="file.safeURL" [ngClass]="{selected: file.selected}" />
    </div>
  </div>
</div>
