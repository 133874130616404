/* eslint-disable @angular-eslint/component-selector */
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { isEmpty } from 'lodash';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'spid-search',
  templateUrl: './spid-search.component.html',
  styleUrls: ['./spid-search.component.scss']
})
export class SpidSearchComponent implements AfterViewInit {

  @Input() placeholderText = 'Search';
  @Input() enableFilter = false;
  @Output() searchTrigger = new EventEmitter();
  @Output() filterTrigger = new EventEmitter();

  previousSearchText = '';

  @ViewChild('searchInput') searchInput: ElementRef = new ElementRef({});

  constructor() { }

  ngAfterViewInit(): void {
    const searchInput = this.searchInput.nativeElement;

    const search$ = fromEvent<KeyboardEvent>(searchInput, 'keyup').pipe(
      filter(
        (e: KeyboardEvent) =>
          (e.key === 'Enter' && !isEmpty(searchInput.value)) ||
          ((e.key === 'Backspace' || e.key === 'Delete') && isEmpty(searchInput.value))
      ),
      map((event: any) => event.target.value),
      distinctUntilChanged()
    );

    search$.subscribe(() => this.onSearch());
  }


  onSearch(): void {
    const searchText = this.searchInput.nativeElement.value;

    if (this.previousSearchText !== searchText) {
      this.previousSearchText = searchText;
      this.searchTrigger.emit({ searchText, clearFilter: false });
    }
  }

  onReset() {
    this.searchInput.nativeElement.value = '';
    this.previousSearchText = '';
    this.searchTrigger.emit({ searchText: '', clearFilter: true });
  }

  onFilter() {
    this.filterTrigger.emit();
  }
}
