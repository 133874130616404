<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path class="cls-1" d="M13.48,32.68a.94.94,0,1,0,.94.93A.93.93,0,0,0,13.48,32.68Z" />
    <path class="cls-1" d="M20.79,33.94H15.36a.22.22,0,1,0,0,.44h5.43a.22.22,0,1,0,0-.44Z" />
    <path class="cls-1" d="M20.1,30.74h2a1,1,0,1,0-2,0Z" />
    <path class="cls-1"
      d="M19.65,30.74a1.44,1.44,0,1,1,2.87,0h0l.43.05c-.27-1.36-1-2.37-1.86-2.37h-6c-.85,0-1.59,1-1.87,2.37a3.22,3.22,0,0,1,.63-.05Z" />
    <path class="cls-1" d="M22.67,32.68a.94.94,0,1,0,.93.93A.94.94,0,0,0,22.67,32.68Z" />
    <path class="cls-1"
      d="M37.29,8.76H10.71a2.52,2.52,0,0,0-2.52,2.52V37.87a2.52,2.52,0,0,0,2.52,2.52H37.29a2.52,2.52,0,0,0,2.52-2.52V11.28A2.52,2.52,0,0,0,37.29,8.76Zm-13,27v1.79H22V36.49H14.13v1.09H11.9V35.79a2.94,2.94,0,0,1-.84-2.18A2.74,2.74,0,0,1,12.47,31c.26-1.9,1.3-3.29,2.59-3.29h6c1.29,0,2.33,1.39,2.59,3.29a2.74,2.74,0,0,1,1.41,2.57A2.94,2.94,0,0,1,24.25,35.79ZM28,26.27a1.14,1.14,0,0,1-.79-.35,1.15,1.15,0,0,1-.32-.8,3.62,3.62,0,0,0-1-2.63,3.67,3.67,0,0,0-2.59-1.14,1.13,1.13,0,1,1,.05-2.25,5.94,5.94,0,0,1,5.82,6.06A1.13,1.13,0,0,1,28,26.27Zm3.71.07a1.09,1.09,0,0,1-.79-.34,1.11,1.11,0,0,1-.31-.81,7.41,7.41,0,0,0-7.25-7.55,1.13,1.13,0,0,1,.05-2.25,9.65,9.65,0,0,1,9.45,9.85A1.12,1.12,0,0,1,31.66,26.34Zm3.72.08a1.12,1.12,0,0,1-1.1-1.15A11.13,11.13,0,0,0,23.39,13.92a1.13,1.13,0,1,1,.05-2.25A13.37,13.37,0,0,1,36.53,25.32,1.12,1.12,0,0,1,35.38,26.42Z" />
    <path class="cls-1" d="M20.79,33.12H15.36a.22.22,0,1,0,0,.44h5.43a.22.22,0,0,0,0-.44Z" />
  </svg>
</div>
