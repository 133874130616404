/* eslint-disable @angular-eslint/component-selector */
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AppState } from 'src/app/utilities/enumeration';

@Component({
  selector: 'spid-notification',
  templateUrl: './spid-notification.component.html',
  styleUrls: ['./spid-notification.component.scss']
})
export class SpidNotificationComponent {

  AppState = AppState;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) { }

}

export class SnackBarData {
  message: string = 'This is informational message';
  category: AppState = AppState.info
}
