import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { find, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Company } from 'src/app/models/company';
import { SearchRequest } from 'src/app/models/search-request';
import { CompanyService } from 'src/app/services/company.service';
import { _normalizeValue } from 'src/app/utilities/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'spid-company-dropdown',
  templateUrl: './spid-company-dropdown.component.html',
  styleUrls: ['./spid-company-dropdown.component.scss']
})
export class SpidCompanyDropdownComponent implements OnInit, OnChanges {

  companySearchRequest!: SearchRequest;
  companies: Company[] = [];
  companies$!: Observable<Company[]>;
  companyCtrl = new FormControl();

  @Input() preSelected!: Company;
  @Input() isReadOnly: boolean = false;
  @Output() selectedTrigger = new EventEmitter<Company>();

  constructor(private companyService: CompanyService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSelectedCompany();
  }

  async ngOnInit(): Promise<void> {
    this.companySearchRequest = {} as SearchRequest;
    await this.getCompanies();

    this.companies$ = this.companyCtrl.valueChanges.pipe(
      startWith(''),
      map(value => typeof (value) === 'string' || isNil(value) ? value : value.name),
      map(value => value ? this._filterCompanies(value) : this.companies.slice())
    );

    this.companyCtrl.valueChanges
      .pipe(
        filter((value) => !!value),
        filter((value) => typeof (value) !== 'string')
      )
      .subscribe(async (value) => {
        this.selectedTrigger.emit(value);
      });
  }

  displayFn(co: Company): string {
    return co.name;
  }

  private _filterCompanies(value: any): any {
    const filterValue = _normalizeValue(value);
    return this.companies.filter(c => _normalizeValue(c.name).includes(filterValue));
  }

  async getCompanies() {
    this.companySearchRequest.pageIndex = null;
    this.companySearchRequest.pageSize = null;
    this.companySearchRequest.sortBy = 'name';
    this.companySearchRequest.sortOrder = 'ASC';
    // this.companySearchRequest.active = true;

    await this.companyService.search(this.companySearchRequest);

    this.companyService.data$.subscribe(data => {
      this.companies = data as Company[];

      this.setSelectedCompany();
    });
  }

  private setSelectedCompany() {
    if (!isNil(this.preSelected)) {
      const selected = find(this.companies, (x) => x.id === this.preSelected.id);
      this.companyCtrl.setValue(selected);
    }
  }
}
