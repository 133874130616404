<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g id="Group_33" data-name="Group 33">
      <g id="Group_18" data-name="Group 18">
        <g id="Group_17" data-name="Group 17">
          <path class="cls-1" id="Path_35" data-name="Path 35" d="M30.27,9.22v8.37h8.38Z" />
        </g>
      </g>
      <g id="Group_20" data-name="Group 20">
        <g id="Group_19" data-name="Group 19">
          <path class="cls-1" id="Path_36" data-name="Path 36"
            d="M29.58,18.78a2.11,2.11,0,0,1-2.26-1.91V9.22H13.73a2.12,2.12,0,0,0-2.27,1.91V37.9a2.12,2.12,0,0,0,2.27,1.91H36.38a2.12,2.12,0,0,0,2.27-1.91V18.78ZM20.52,36H16V30.25h4.53Zm6.8,0H22.79V26.43h4.53Zm6.79,0H29.58V22.6h4.54Z" />
        </g>
      </g>
    </g>
  </svg>
</div>
