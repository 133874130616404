<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-2 {
          fill: none;
        }

        .cls-1 {
          fill: #000000;
        }

      </style>
    </defs>
    <g id="settings_applications-24px" data-name="settings applications-24px">
      <path id="Path_40" data-name="Path 40" class="cls-2" d="M4.46,3.28H45.89V44.72H4.46Z" />
      <path id="Path_41" data-name="Path 41" class="cls-1"
        d="M25.18,20.55A3.45,3.45,0,1,0,28.63,24,3.45,3.45,0,0,0,25.18,20.55ZM37.26,8.46H13.09a3.46,3.46,0,0,0-3.45,3.45h0V36.09a3.45,3.45,0,0,0,3.45,3.45H37.26a3.45,3.45,0,0,0,3.45-3.45h0V11.92a3.45,3.45,0,0,0-3.45-3.46ZM34.24,24a7.59,7.59,0,0,1-.09,1.17l2.56,2a.61.61,0,0,1,.14.78l-2.42,4.18a.6.6,0,0,1-.74.26l-3-1.21a9.46,9.46,0,0,1-2,1.19l-.45,3.19a.62.62,0,0,1-.6.52H22.76a.63.63,0,0,1-.6-.5l-.45-3.19a9.46,9.46,0,0,1-2-1.19l-3,1.2a.6.6,0,0,1-.74-.26L13.51,28a.61.61,0,0,1,.13-.78l2.56-2A11.69,11.69,0,0,1,16.11,24a9.22,9.22,0,0,1,.09-1.17l-2.56-2a.61.61,0,0,1-.13-.78l2.41-4.18a.64.64,0,0,1,.75-.26l3,1.21a9.39,9.39,0,0,1,2-1.19l.45-3.19a.62.62,0,0,1,.6-.52h4.84a.63.63,0,0,1,.6.5l.44,3.19a9.23,9.23,0,0,1,2,1.19l3-1.2a.6.6,0,0,1,.74.26L36.83,20a.61.61,0,0,1-.14.78l-2.55,2A9.51,9.51,0,0,1,34.22,24Z" />
    </g>
  </svg>

</div>
