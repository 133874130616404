<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path id="Path_21" data-name="Path 21" class="cls-1"
      d="M7,17.45a1.68,1.68,0,0,0,1.68,1.67h6.7A1.68,1.68,0,0,0,17,17.45V9.07a1.68,1.68,0,0,0-1.68-1.68H8.65A1.68,1.68,0,0,0,7,9.07ZM17,4.88h-2.1l-.59-.6A.87.87,0,0,0,13.75,4h-3.5a.87.87,0,0,0-.59.24l-.59.6H7A.84.84,0,0,0,7,6.55H17a.84.84,0,0,0,0-1.67Z" />
  </svg>
</div>
