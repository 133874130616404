import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SpidSvgService } from '../../services/spid-svg-service';
import { SpidSvgComponent } from '../../ui/spid-svg/spid-svg.component';

@Component({
  selector: 'app-svg-my-profile',
  templateUrl: './svg-my-profile.component.html',
  styleUrls: ['../../../shared/ui/spid-svg/spid-svg.component.scss']
})
export class SvgMyProfileComponent extends SpidSvgComponent {
  constructor(
    public sanitizer: DomSanitizer,
    public svgIconService: SpidSvgService) {
    super(sanitizer, svgIconService);
  }
}
