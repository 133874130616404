import { Component, ComponentFactoryResolver, Inject, OnInit, Renderer2, Type, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SvgIconDirective } from '../svg-icon.directive';

@Component({
  selector: 'app-spid-confirm-dialog',
  templateUrl: './spid-confirm-dialog.component.html',
  styleUrls: ['./spid-confirm-dialog.component.scss']
})
export class SpidConfirmDialogComponent implements OnInit {

  @ViewChild(SvgIconDirective, { static: true }) svgIconHost!: SvgIconDirective;

  constructor(
    public dialogRef: MatDialogRef<SpidConfirmDialogComponent>,
    private cfr: ComponentFactoryResolver,
    private renderer2: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }

  ngOnInit(): void {
    const viewContainerRef = this.svgIconHost.viewContainerRef;
    viewContainerRef.clear();
    const component = this.cfr.resolveComponentFactory(this.data.icon);
    const componentRef = viewContainerRef.createComponent(component);
    this.renderer2.addClass(componentRef.location.nativeElement, 'app-svg');
    this.renderer2.setAttribute(componentRef.location.nativeElement, 'clickable', 'false');
  }

  submit() {
    this.dialogRef.close({
      proceed: true,
      data: this.data
    });
  }
}

export interface DialogData {
  title: string;
  message: string;
  hasRemarks: boolean;
  remarks: string;
  icon: Type<Component>;
}
