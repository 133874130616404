<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 164.2 73.3" style="enable-background:new 0 0 164.2 73.3;" xml:space="preserve">
    <style type="text/css">
      .st0 {
        enable-background: new;
      }

      .st1 {
        fill: #FFFFFF;
      }

    </style>
    <g id="Group_38">
      <g id="SMART_PARKING_TECHNOLOGIES">
        <g class="st0">
          <path class="st1" d="M12.1,56.3c0,0.3,0.1,0.5,0.2,0.7c0.2,0.3,0.6,0.4,1.1,0.4c0.3,0,0.6,0,0.8-0.1c0.4-0.1,0.6-0.4,0.6-0.7
				c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.5-0.2-0.9-0.3L13,55.6c-0.7-0.2-1.1-0.3-1.4-0.5c-0.4-0.3-0.6-0.8-0.6-1.4
				c0-0.6,0.2-1,0.6-1.4s1-0.6,1.9-0.6c0.7,0,1.3,0.2,1.7,0.5s0.7,0.9,0.8,1.6h-1.3c0-0.4-0.2-0.7-0.5-0.8c-0.2-0.1-0.5-0.2-0.8-0.2
				c-0.4,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.2,0.1,0.4,0.3,0.5c0.1,0.1,0.4,0.2,0.9,0.3l1.1,0.3
				c0.5,0.1,0.9,0.3,1.1,0.5c0.4,0.3,0.6,0.7,0.6,1.3c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1.1,0.6-1.9,0.6c-0.8,0-1.5-0.2-2-0.6
				c-0.5-0.4-0.7-0.9-0.7-1.6H12.1z" />
          <path class="st1" d="M21.3,51.9h1.9v6.4H22V54c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5l-1.2,5.4h-1.3l-1.2-5.4c0,0.1,0,0.3,0,0.5
				c0,0.2,0,0.4,0,0.5v4.3h-1.2v-6.4H19l1.2,5L21.3,51.9z" />
          <path class="st1"
            d="M26.3,51.9h1.5l2.3,6.4h-1.4L28.2,57h-2.4l-0.4,1.3H24L26.3,51.9z M26.2,55.9h1.6l-0.8-2.5L26.2,55.9z" />
        </g>
        <g class="st0">
          <path class="st1" d="M35,52.1c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.7c0,0.3-0.1,0.6-0.2,0.9
				s-0.4,0.5-0.8,0.6c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.2,0.6,0.2,1v0.4c0,0.3,0,0.5,0,0.6c0,0.2,0.1,0.3,0.2,0.4v0.2h-1.5
				c0-0.1-0.1-0.3-0.1-0.3c0-0.2-0.1-0.4-0.1-0.6l0-0.6c0-0.4-0.1-0.7-0.2-0.8c-0.1-0.1-0.4-0.2-0.8-0.2h-1.3v2.5h-1.3v-6.4H34
				C34.4,51.9,34.8,52,35,52.1z M32.2,53v1.7h1.4c0.3,0,0.5,0,0.6-0.1c0.3-0.1,0.4-0.4,0.4-0.7c0-0.4-0.1-0.6-0.4-0.8
				C34.2,53.1,34,53,33.7,53H32.2z" />
        </g>
        <g class="st0">
          <path class="st1" d="M41.8,51.9V53h-1.9v5.3h-1.3V53h-1.9v-1.1H41.8z" />
        </g>
        <g class="st0">
          <path class="st1" d="M49.4,55.5C49,55.8,48.4,56,47.7,56h-1.3v2.3h-1.3v-6.4h2.7c0.6,0,1.1,0.2,1.5,0.5s0.6,0.8,0.6,1.5
				C49.9,54.7,49.7,55.2,49.4,55.5z M48.3,53.2c-0.2-0.1-0.4-0.2-0.7-0.2h-1.2v1.9h1.2c0.3,0,0.5-0.1,0.7-0.2s0.3-0.4,0.3-0.7
				S48.5,53.4,48.3,53.2z" />
        </g>
        <g class="st0">
          <path class="st1"
            d="M51.9,51.9h1.5l2.3,6.4h-1.4L53.8,57h-2.4L51,58.3h-1.4L51.9,51.9z M51.8,55.9h1.6l-0.8-2.5L51.8,55.9z" />
          <path class="st1" d="M60.6,52.1c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.7c0,0.3-0.1,0.6-0.2,0.9
				s-0.4,0.5-0.8,0.6c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.2,0.6,0.2,1v0.4c0,0.3,0,0.5,0,0.6c0,0.2,0.1,0.3,0.2,0.4v0.2h-1.5
				c0-0.1-0.1-0.3-0.1-0.3c0-0.2-0.1-0.4-0.1-0.6l0-0.6c0-0.4-0.1-0.7-0.2-0.8c-0.1-0.1-0.4-0.2-0.8-0.2h-1.3v2.5h-1.3v-6.4h3.1
				C60,51.9,60.4,52,60.6,52.1z M57.9,53v1.7h1.4c0.3,0,0.5,0,0.6-0.1c0.3-0.1,0.4-0.4,0.4-0.7c0-0.4-0.1-0.6-0.4-0.8
				c-0.1-0.1-0.3-0.1-0.6-0.1H57.9z" />
          <path class="st1" d="M63,51.9h1.3v2.6l2.5-2.6h1.7l-2.6,2.6l2.8,3.8h-1.7l-2-2.8l-0.6,0.7v2.1H63V51.9z" />
          <path class="st1" d="M70.6,58.3h-1.3v-6.4h1.3V58.3z" />
          <path class="st1" d="M71.8,51.9h1.4l2.5,4.5v-4.5H77v6.4h-1.3l-2.6-4.5v4.5h-1.2V51.9z" />
          <path class="st1" d="M82.5,53.9c-0.1-0.4-0.3-0.7-0.7-0.9c-0.2-0.1-0.5-0.1-0.7-0.1c-0.5,0-0.9,0.2-1.3,0.6
				c-0.3,0.4-0.5,1-0.5,1.8c0,0.8,0.2,1.3,0.5,1.7c0.4,0.3,0.8,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.5-1h-1.5v-1.1
				h2.7v3.4H83l-0.1-0.8c-0.3,0.3-0.5,0.5-0.7,0.6c-0.4,0.2-0.8,0.3-1.3,0.3c-0.8,0-1.5-0.3-2.1-0.9c-0.6-0.6-0.8-1.4-0.8-2.4
				c0-1,0.3-1.9,0.9-2.5c0.6-0.6,1.3-0.9,2.3-0.9c0.8,0,1.5,0.2,2,0.6c0.5,0.4,0.8,0.9,0.8,1.5H82.5z" />
        </g>
        <g class="st0">
        </g>
        <g class="st0">
          <path class="st1" d="M91.4,51.9V53h-1.9v5.3h-1.3V53h-1.9v-1.1H91.4z" />
          <path class="st1" d="M96.8,53h-3.3v1.4h3v1.1h-3v1.6H97v1.2h-4.8v-6.4h4.6V53z" />
          <path class="st1" d="M98.7,52.6c0.5-0.5,1.2-0.8,2-0.8c1.1,0,1.8,0.4,2.3,1.1c0.3,0.4,0.4,0.8,0.4,1.2h-1.3
				c-0.1-0.3-0.2-0.5-0.3-0.7c-0.2-0.3-0.6-0.4-1.1-0.4c-0.5,0-0.9,0.2-1.1,0.6c-0.3,0.4-0.4,0.9-0.4,1.7s0.1,1.3,0.4,1.6
				s0.7,0.5,1.1,0.5c0.5,0,0.8-0.2,1-0.5c0.1-0.2,0.2-0.4,0.3-0.7h1.3c-0.1,0.7-0.4,1.3-0.9,1.7c-0.5,0.4-1.1,0.7-1.8,0.7
				c-0.9,0-1.6-0.3-2.1-0.9c-0.5-0.6-0.8-1.4-0.8-2.4C97.8,54,98.1,53.2,98.7,52.6z" />
          <path class="st1" d="M104.5,58.3v-6.4h1.3v2.4h2.5v-2.4h1.3v6.4h-1.3v-2.9h-2.5v2.9H104.5z" />
          <path class="st1" d="M110.9,51.9h1.4l2.5,4.5v-4.5h1.2v6.4h-1.3l-2.6-4.5v4.5h-1.2V51.9z" />
          <path class="st1" d="M122.3,57.7c-0.5,0.5-1.2,0.7-2.1,0.7c-0.9,0-1.6-0.2-2.1-0.7c-0.6-0.6-1-1.5-1-2.6c0-1.2,0.3-2.1,1-2.6
				c0.5-0.5,1.2-0.7,2.1-0.7c0.9,0,1.6,0.2,2.1,0.7c0.6,0.6,1,1.5,1,2.6C123.3,56.2,122.9,57.1,122.3,57.7z M121.5,56.8
				c0.3-0.4,0.5-0.9,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7c-0.3-0.4-0.7-0.6-1.3-0.6c-0.5,0-1,0.2-1.3,0.6s-0.5,0.9-0.5,1.7
				s0.2,1.3,0.5,1.7s0.7,0.6,1.3,0.6C120.7,57.3,121.1,57.1,121.5,56.8z" />
          <path class="st1" d="M124.3,51.9h1.3v5.2h3.2v1.2h-4.5V51.9z" />
          <path class="st1" d="M134.6,57.7c-0.5,0.5-1.2,0.7-2.1,0.7c-0.9,0-1.6-0.2-2.1-0.7c-0.6-0.6-1-1.5-1-2.6c0-1.2,0.3-2.1,1-2.6
				c0.5-0.5,1.2-0.7,2.1-0.7c0.9,0,1.6,0.2,2.1,0.7c0.6,0.6,1,1.5,1,2.6C135.6,56.2,135.3,57.1,134.6,57.7z M133.8,56.8
				c0.3-0.4,0.5-0.9,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7c-0.3-0.4-0.7-0.6-1.3-0.6s-1,0.2-1.3,0.6s-0.5,0.9-0.5,1.7s0.2,1.3,0.5,1.7
				s0.7,0.6,1.3,0.6S133.5,57.1,133.8,56.8z" />
          <path class="st1" d="M140.9,53.9c-0.1-0.4-0.3-0.7-0.7-0.9c-0.2-0.1-0.5-0.1-0.7-0.1c-0.5,0-0.9,0.2-1.3,0.6s-0.5,1-0.5,1.8
				c0,0.8,0.2,1.3,0.5,1.7c0.4,0.3,0.8,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.5-1h-1.5v-1.1h2.7v3.4h-0.9l-0.1-0.8
				c-0.3,0.3-0.5,0.5-0.7,0.6c-0.4,0.2-0.8,0.3-1.3,0.3c-0.8,0-1.5-0.3-2.1-0.9c-0.6-0.6-0.8-1.4-0.8-2.4c0-1,0.3-1.9,0.9-2.5
				c0.6-0.6,1.3-0.9,2.3-0.9c0.8,0,1.5,0.2,2,0.6c0.5,0.4,0.8,0.9,0.8,1.5H140.9z" />
          <path class="st1" d="M144.8,58.3h-1.3v-6.4h1.3V58.3z" />
          <path class="st1" d="M150.7,53h-3.3v1.4h3v1.1h-3v1.6h3.5v1.2h-4.8v-6.4h4.6V53z" />
          <path class="st1" d="M152.9,56.3c0,0.3,0.1,0.5,0.2,0.7c0.2,0.3,0.6,0.4,1.1,0.4c0.3,0,0.6,0,0.8-0.1c0.4-0.1,0.6-0.4,0.6-0.7
				c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.5-0.2-0.9-0.3l-0.7-0.2c-0.7-0.2-1.1-0.3-1.4-0.5c-0.4-0.3-0.6-0.8-0.6-1.4
				c0-0.6,0.2-1,0.6-1.4s1-0.6,1.9-0.6c0.7,0,1.3,0.2,1.7,0.5c0.5,0.4,0.7,0.9,0.8,1.6h-1.3c0-0.4-0.2-0.7-0.5-0.8
				c-0.2-0.1-0.5-0.2-0.8-0.2c-0.4,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.2,0.1,0.4,0.3,0.5c0.1,0.1,0.4,0.2,0.9,0.3
				l1.1,0.3c0.5,0.1,0.9,0.3,1.1,0.5c0.4,0.3,0.6,0.7,0.6,1.3c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1.1,0.6-1.9,0.6c-0.8,0-1.5-0.2-2-0.6
				c-0.5-0.4-0.7-0.9-0.7-1.6H152.9z" />
        </g>
      </g>
      <path id="Path_1" class="st1" d="M56.8,47.4h-19v-6.9h15.2v-6H41.7c-1,0-1.9-0.3-2.7-1c-0.7-0.6-1.2-1.5-1.2-2.5V18.4
		c0-1,0.4-1.8,1.2-2.4c0.7-0.7,1.7-1,2.7-1h18.9v6.9H45.5v5.7h11.3c1,0,1.9,0.3,2.7,1c0.7,0.6,1.1,1.5,1.1,2.5v13
		c0,0.9-0.4,1.9-1.1,2.5C58.7,47.1,57.8,47.5,56.8,47.4z" />
      <path id="Path_2" class="st1" d="M86.1,15.2c1,0,1.9,0.3,2.7,1c0.7,0.6,1.1,1.5,1.1,2.4v14.3c0,0.9-0.4,1.9-1.1,2.5
		c-0.7,0.7-1.7,1-2.7,1H71.7v11.1H64V15.2H86.1z M82.3,22H71.7v7.5h10.6V22z" />
      <g id="Group_1">
        <rect id="Rectangle_4" x="93.3" y="22.1" class="st1" width="7.6" height="2.9" />
        <path id="Path_3" class="st1" d="M100.9,29.5h-7.6v16.7h-2.1v1.2H103v-1.2h-2L100.9,29.5z" />
      </g>
      <rect id="Rectangle_5" x="93.3" y="26" width="27.9" height="2.4" />
      <path id="Path_4" class="st1" d="M129.6,16.2c-0.7-0.7-1.7-1-2.7-1h-21.8V25h7.5v-2.9h10.4v18.5h-10.4V29.5h-7.5v17.9h21.8
		c1,0,1.9-0.3,2.7-1c0.7-0.6,1.1-1.5,1.1-2.4V18.6C130.7,17.7,130.3,16.8,129.6,16.2z" />
    </g>
  </svg>

</div>
