<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path d="M34.78,29.45l-3.65,4.78a3.86,3.86,0,0,1-3.07,1.52H21.27a1,1,0,0,1,0-1.93h5.88a2.29,2.29,0,0,0,2.3-2.3,2.25,2.25,0,0,0-2.25-2.21H20.44a5.6,5.6,0,0,0-7.14-.52l-.72.52v11h16.8a5.83,5.83,0,0,0,4.88-2.66L38,31.73a2,2,0,0,0,.31-1.07,2,2,0,0,0-3.56-1.21Z" />
    <path d="M12.64,27.37H9.29a1,1,0,0,0-1,.93V40.61a1,1,0,0,0,1,.93h3.35a1,1,0,0,0,1-.93V28.3A1,1,0,0,0,12.64,27.37Z" />
    <path d="M28.18,17H26.53v2.67h1.85a1.31,1.31,0,0,0,1.4-1.33C29.77,17.35,29.16,17,28.18,17Z" />
    <path d="M27.83,11.52a8.25,8.25,0,1,0,8.25,8.25A8.25,8.25,0,0,0,27.83,11.52Zm3.93,6.78c0,.11,0,.21,0,.31h.55a.58.58,0,0,1,0,1.16h-.87a2.84,2.84,0,0,1-2.71,1.51h-2.2v2.55a1,1,0,1,1-2,0V19.75h-.74a.58.58,0,0,1,0-1.16h.75V18.1h-.75a.58.58,0,1,1,0-1.15h.74v-.39c0-1,.47-1.25,1.17-1.25h3A2.76,2.76,0,0,1,31.53,17h.78a.55.55,0,0,1,.58.58.55.55,0,0,1-.59.58h-.55C31.75,18.18,31.76,18.24,31.76,18.3Z" />
    <path d="M35.92,8.16a.67.67,0,0,0-.68.67V10a12,12,0,0,0-15.6,1,11.51,11.51,0,0,0,0,16.47.71.71,0,0,0,.49.2.69.69,0,0,0,.48-.2.66.66,0,0,0,0-.95,10.15,10.15,0,0,1-3.07-7.28A10.4,10.4,0,0,1,28,9a10.57,10.57,0,0,1,6,1.87h-.86a.68.68,0,1,0,0,1.35h2.93l.05,0h0l0,0h0l0,0h0a.66.66,0,0,0,.19-.18h0l0-.05h0l0-.05h0s0,0,0,0h0a.13.13,0,0,0,0-.06h0a.37.37,0,0,0,0-.12V8.83A.68.68,0,0,0,35.92,8.16Z" />
    <path d="M34.92,26.73l.52-.6.6.53-.53.59Z" />
    <path d="M36.17,25.13l.4-.68.68.4-.4.68Z" />
    <path d="M37.08,23.33l.26-.75.75.25-.26.76Z" />
    <path d="M37.62,21.38l.11-.79.79.11-.11.78Z" />
    <path d="M37.78,19.36l-.05-.8.79,0,0,.79Z" />
    <path d="M37.53,17.35l-.2-.77.77-.2.2.76Z" />
    <path d="M36.9,15.42l-.34-.71.71-.35.35.72Z" />
  </svg>
</div>