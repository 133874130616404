export enum AppState {
  info = 'information',
  warn = 'warning',
  error = 'error'
}

export enum DrawerEvent {
  Closed,
  VehicleIn,
  VehicleOut,
  GuestParking,
  ValetService,
  FixedRateCharge,
  Discount,
  LostCard,
  LostCardUnknown,
  FreeOfCharge,
  AdvancePayment,
  SearchVehicle,
  BarrierOverride,
  AvailableSlots,
  Overstaying,
  VehicleInside,
  VehicleForTagging,
  VIPInside,
  GuestAvailableSlot,
  TagVehicle,
  TransactionPayment,
  Void,
  Refund,
  Mismatch,
  CashIn,
  CashOut,
  GuestParkingAssignment,
}

export enum TransactionCharges {
  DISCOUNT = 'Discount',
  FIXED = 'Fixed',
  FOC = 'FOC',
  VALET = 'ValetService'
}


export enum TransactionStatus {
  ForPayment = 'ForPayment',
  ForPrintingReceipt = "ForPrintingReceipt",
  In = 'In',
  Paid = 'Paid',
  Out = 'Out'
}

export enum GuestParkingView {
  GuestList = 'GuestList',
  AddGuest = 'AddGuest',
  GuestAssignment = 'GuestAssignment'
}
