<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1{fill:#000000;}
      </style>
    </defs>
    <path class="cls-1" d="M39.3,9.34H10.73a1.25,1.25,0,0,0-1.26,1.25v6.23H40.55V10.59A1.25,1.25,0,0,0,39.3,9.34Zm-13,5.41A1.67,1.67,0,1,1,28,13.08,1.67,1.67,0,0,1,26.33,14.75Zm4.54,0a1.67,1.67,0,1,1,1.67-1.67A1.67,1.67,0,0,1,30.87,14.75Zm4.55,0a1.67,1.67,0,1,1,1.67-1.67A1.67,1.67,0,0,1,35.42,14.75Z" />
    <path class="cls-1" d="M9.47,19.32v18a1.25,1.25,0,0,0,1.26,1.25H39.3a1.25,1.25,0,0,0,1.25-1.25v-18ZM23.93,29.89l-4.1,3.48a1.24,1.24,0,0,1-1.76-.14,1.25,1.25,0,0,1,.14-1.77l3-2.53-3-2.53a1.25,1.25,0,0,1,1.62-1.91L23.93,28A1.27,1.27,0,0,1,23.93,29.89Zm8,3.78H27a1.25,1.25,0,0,1,0-2.5h5a1.25,1.25,0,0,1,0,2.5Z" />
  </svg>  
</div>