<div class="svg-icon">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.63 49.77">
    <defs>
      <style>
        .cls-1 {
          fill: #fff;
        }

        .cls-2 {
          fill: #000;
        }

      </style>
    </defs>
    <g id="Group_57" data-name="Group 57">
      <path id="Path_1" data-name="Path 1" class="cls-1"
        d="M40,39.11H22.13V32.65H36.36V27H25.74A3.75,3.75,0,0,1,23.2,26a3,3,0,0,1-1.07-2.33V11.82A2.83,2.83,0,0,1,23.2,9.57a3.71,3.71,0,0,1,2.54-.94H43.51v6.45H29.34v5.35H40a3.58,3.58,0,0,1,2.49.94,3,3,0,0,1,1,2.33V35.85a3,3,0,0,1-1,2.33A3.62,3.62,0,0,1,40,39.11Z" />
      <path id="Path_2" data-name="Path 2" class="cls-1"
        d="M67.56,8.76a3.61,3.61,0,0,1,2.5.94,3,3,0,0,1,1,2.26V25.43a3,3,0,0,1-1,2.32,3.56,3.56,0,0,1-2.5.94H54V39.11H46.8V8.76ZM64,15.22H54v7H64Z" />
      <g id="Group_1" data-name="Group 1">
        <rect id="Rectangle_4" data-name="Rectangle 4" class="cls-1" x="74.37" y="15.29" width="7.15" height="2.71" />
        <path id="Path_3" data-name="Path 3" class="cls-1" d="M81.53,22.24H74.37V38h-2v1.15h11V38h-1.9Z" />
      </g>
      <rect id="Rectangle_5" data-name="Rectangle 5" class="cls-2" x="74.38" y="18.96" width="26.23" height="2.29" />
      <path id="Path_4" data-name="Path 4" class="cls-1"
        d="M108.48,9.7a3.67,3.67,0,0,0-2.5-.94H85.49V18h7.09v-2.7h9.79V32.72H92.58V22.24H85.49V39.11H106a3.68,3.68,0,0,0,2.49-.9,2.94,2.94,0,0,0,1-2.29V12A3.06,3.06,0,0,0,108.48,9.7Z" />
    </g>
  </svg>

</div>
