import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { find, isNil, join } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Location } from 'src/app/models/location';
import { SearchRequest } from 'src/app/models/search-request';
import { LocationService } from 'src/app/services/location.service';
import { _normalizeValue } from 'src/app/utilities/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'spid-location-dropdown',
  templateUrl: './spid-location-dropdown.component.html',
  styleUrls: ['./spid-location-dropdown.component.scss']
})
export class SpidLocationDropdownComponent implements OnInit, OnChanges {

  locationSearchRequest!: SearchRequest;
  locations: Location[] = [];
  locations$!: Observable<Location[]>;
  locationCtrl = new FormControl();

  @Input() preSelected!: Location;
  @Input() isReadOnly: boolean = false;
  @Output() selectedTrigger = new EventEmitter<Location>();

  constructor(private locationService: LocationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSelectedLocation();
  }

  async ngOnInit(): Promise<void> {
    this.locationSearchRequest = {} as SearchRequest;
    await this.getLocations();

    this.locations$ = this.locationCtrl.valueChanges.pipe(
      startWith(''),
      map(value => typeof (value) === 'string' || isNil(value) ? value : value.name),
      map(value => value ? this._filterLocations(value) : this.locations.slice())
    );

    this.locationCtrl.valueChanges
      .pipe(
        filter((value) => !!value),
        filter((value) => typeof (value) !== 'string')
      )
      .subscribe(async (value) => {
        this.selectedTrigger.emit(value);
      });
  }

  displayFn(loc: Location): string {
    return loc && loc.name ? join([loc.name,
    loc.branch ? `| ${loc.branch}` : '',
    loc.organization ? `| ${loc.organization}` : '',
    loc.company ? `| ${loc.company}` : ''], '') : '';
  }

  private _filterLocations(value: any): any {
    const filterValue = _normalizeValue(value);
    return this.locations.filter(c => _normalizeValue(c.name).includes(filterValue));
  }

  async getLocations() {
    this.locationSearchRequest.pageIndex = null;
    this.locationSearchRequest.pageSize = null;
    this.locationSearchRequest.sortBy = 'name';
    this.locationSearchRequest.sortOrder = 'ASC';

    await this.locationService.search(this.locationSearchRequest);

    this.locationService.data$.subscribe(data => {
      this.locations = data as Location[];
      this.setSelectedLocation();
    });
  }

  private setSelectedLocation() {
    if (!isNil(this.preSelected)) {
      const selected = find(this.locations, (x) => x.id === this.preSelected.id);
      this.locationCtrl.setValue(selected);
    }
  }
}
