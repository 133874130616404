<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
    <g>
      <path
        d="M37.4,16.5h-2.2L12.3,9.2c-0.1,0-0.2,0-0.3,0c-0.5,0-0.9,0.3-1,0.7l-0.5,1.6c-0.1,0.3-0.1,0.6,0.1,0.8
		c0.1,0.3,0.3,0.4,0.6,0.5L33.5,20v16c0,0.6,0.5,1.1,1.1,1.1h2.8c0.6,0,1.1-0.5,1.1-1.1V17.6C38.5,17,38,16.5,37.4,16.5z M14,11.2
		L14,11.2l-0.3,1c-0.1,0.3-0.3,0.4-0.6,0.5c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8l0.4-1.1c0.1-0.3,0.5-0.5,0.9-0.3
		C13.9,10.6,14.1,10.9,14,11.2z M17.4,13.4c-0.1,0.3-0.3,0.4-0.6,0.5c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8c0,0,0,0,0,0
		l0.3-1.1c0.1-0.3,0.5-0.5,0.8-0.4c0,0,0,0,0,0c0.3,0.1,0.5,0.5,0.4,0.8l0.1,0L17.4,13.4z M21.6,13.6C21.6,13.6,21.6,13.6,21.6,13.6
		L21.6,13.6l-0.3,1.1c-0.1,0.3-0.3,0.5-0.6,0.5c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8c0,0,0,0,0,0l0.3-1.1
		c0.1-0.3,0.5-0.5,0.8-0.4C21.5,12.9,21.7,13.2,21.6,13.6z M25.3,14.8L25.3,14.8l-0.3,1c-0.1,0.3-0.3,0.5-0.6,0.5
		c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8c0,0,0,0,0,0l0.3-1.1c0.1-0.3,0.5-0.5,0.9-0.3C25.2,14.2,25.4,14.5,25.3,14.8z
		 M29.1,16L29.1,16l-0.3,1c-0.1,0.3-0.3,0.5-0.6,0.5c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8l0.4-1.1c0.1-0.3,0.5-0.5,0.9-0.3
		C29,15.4,29.1,15.7,29.1,16z M32.8,17.2C32.8,17.2,32.8,17.2,32.8,17.2L32.8,17.2l-0.4,1.1c-0.1,0.3-0.3,0.4-0.6,0.5
		c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.5-0.4-0.8c0,0,0,0,0,0l0.3-1.1c0.1-0.3,0.5-0.5,0.8-0.4C32.8,16.5,32.9,16.9,32.8,17.2z" />
      <path d="M26.2,36c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-1.3h-3.1V36z" />
      <path d="M11.9,36c0,0.9,0.6,1.6,1.5,1.6S15,37,15,36.2c0-0.1,0-0.1,0-0.2v-1.3h-3.1V36z" />
      <path d="M29,25.5l-1.7-4.4c-0.3-1-1.3-1.6-2.3-1.6h-8.9c-1,0-2,0.6-2.3,1.6l-1.7,4.4c-1.1,0.3-1.9,1.3-1.9,2.4v4.3
		c0,0.7,0.6,1.3,1.3,1.3h18.2c0.7,0,1.3-0.6,1.3-1.3v-4.3C31,26.7,30.2,25.7,29,25.5z M13.7,30.3c-0.8,0-1.5-0.7-1.5-1.5
		s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S14.5,30.3,13.7,30.3z M20.6,25.4h-5.3c-0.4,0-0.7-0.3-0.7-0.7c0-0.1,0-0.2,0.1-0.3l0.9-2.1
		c0.1-0.3,0.4-0.8,1-0.8h8.1c0.6,0,0.7,0.3,1,0.8l0.9,2.1c0.1,0.3,0,0.7-0.4,0.9c-0.1,0-0.2,0.1-0.3,0H20.6z M27.4,30.4
		c-0.8-0.1-1.4-0.8-1.4-1.6c0.1-0.7,0.6-1.3,1.4-1.4c0.8,0.1,1.4,0.8,1.4,1.6C28.7,29.8,28.1,30.4,27.4,30.4z" />
      <path d="M23.5,23.4c-0.9,0.1-1.6,0.8-1.7,1.7h0.9c0.1-0.4,0.5-0.8,0.9-0.8c0.5,0,0.8,0.3,0.9,0.8h1C25.5,24.1,24.5,23.3,23.5,23.4z
		" />
    </g>
  </svg>
</div>
