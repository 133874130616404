<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
    <g>
      <path d="M25.6,31.6c-0.7-0.6-1.8-0.6-2.4,0.1c-0.7,0.8-0.7,1.9,0,2.7c0.3,0.4,0.8,0.6,1.3,0.6c0.5,0,0.9-0.2,1.3-0.5
		c0.3-0.4,0.5-0.8,0.5-1.3c0-0.5-0.2-1-0.5-1.4C25.7,31.7,25.7,31.6,25.6,31.6z" />
      <path d="M28.5,19c-0.5-0.4-1.1-0.7-1.8-0.8c-0.7-0.2-1.3-0.3-2-0.3c-0.7,0-1.3,0.1-1.9,0.3c-0.6,0.2-1.1,0.5-1.6,0.9
		c-0.5,0.4-0.9,0.8-1.2,1.4c-0.3,0.5-0.4,1-0.4,1.6c0,0.4,0.1,0.8,0.4,1c0.3,0.2,0.6,0.4,1,0.4c0.3,0,0.6-0.1,0.8-0.2
		c0.2-0.2,0.4-0.3,0.5-0.6c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.4-0.4,0.7-0.6c0.3-0.2,0.7-0.2,1.1-0.2
		c0.6,0,1.1,0.2,1.5,0.5c0.4,0.4,0.6,1,0.5,1.5c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.3-0.6,0.5-0.9,0.8
		c-0.3,0.2-0.6,0.5-0.9,0.8c-0.3,0.3-0.5,0.6-0.7,1c-0.2,0.4-0.3,0.8-0.3,1.2c0,0.4,0.2,0.8,0.4,1.1c0.2,0.3,0.6,0.5,1,0.5
		c0.3,0,0.6,0,0.8-0.2c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.5-0.5l0.8-0.6
		c0.3-0.2,0.6-0.5,0.9-0.7c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.3,0.5-0.7,0.7-1.1c0.1-0.4,0.2-0.9,0.2-1.4c0-0.7-0.1-1.5-0.5-2.1
		C29.4,19.9,29,19.4,28.5,19z" />
      <path
        d="M38.9,11.6H10c-2.8,0-5.1,2.3-5.1,5.1v17.9c0,2.8,2.3,5.1,5.1,5.1h28.9c2.8,0,5.1-2.3,5.1-5.1V16.8
		C44.1,13.9,41.8,11.6,38.9,11.6z M41.2,34.7c0,1.2-1,2.2-2.2,2.2H10c-1.2,0-2.2-1-2.2-2.2V20.1h9.7c0,0,0.6-1.2,0.7-1.3
		c0.3-0.5,0.7-0.9,1.2-1.3c1.1-0.9,2.5-1.6,3.9-1.9c1.2-0.3,2.5-0.3,3.7,0c1.1,0.2,2.1,0.7,3,1.4c1,0.8,1.7,1.9,2.3,3h8.9V34.7z" />
    </g>
  </svg>

</div>
