<div class="spid-confirm-dialog">
  <div mat-dialog-title class="title">
    <!-- <app-svg-delete class="app-svg" [clickable]="false"></app-svg-delete> -->
    <ng-container appSvgIcon></ng-container>
    <span>{{data.title}}</span>
  </div>
  <div mat-dialog-content>
    {{data.message}}
    <div class="remarks" *ngIf="!!data.hasRemarks">
      <div>
        <label>Remarks</label>
      </div>
      <div>
        <textarea [(ngModel)]="data.remarks"></textarea>
      </div>
    </div>

  </div>
  <div mat-dialog-actions class="actions">
    <button mat-dialog-close="false">No</button>
    <button mat-dialog-close="true" *ngIf="!data.hasRemarks">Yes</button>
    <button (click)="submit()" *ngIf="!!data.hasRemarks"
      [disabled]="(data.remarks || '').length === 0">Yes</button>
  </div>
</div>
