import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SpidSvgService } from '../../services/spid-svg-service';
import { SpidSvgComponent } from '../../ui/spid-svg/spid-svg.component';

@Component({
  selector: 'app-svg-refund',
  templateUrl: './svg-refund.component.html',
  styleUrls: ['../../../shared/ui/spid-svg/spid-svg.component.scss']
})
export class SvgRefundComponent extends SpidSvgComponent {
  constructor(
    public sanitizer: DomSanitizer,
    public svgIconService: SpidSvgService) {
    super(sanitizer, svgIconService);
    this.toolTipText = 'Refund';
  }
}
