<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g id="car-fill-from-frontal-view">
      <path id="Path_26" data-name="Path 26" d="M39,37.27V35.41H34.3v1.86a2.36,2.36,0,0,0,4.71,0Z" />
      <path id="Path_27" data-name="Path 27" d="M17.4,37.27V35.41H12.71v1.86a2.35,2.35,0,0,0,4.69.25Z" />
      <path id="Path_28" data-name="Path 28"
        d="M41.51,31.55V25a3.62,3.62,0,0,0-2.84-3.6L36.1,14.81a3.49,3.49,0,0,0-3.51-2.35H19.1a3.5,3.5,0,0,0-3.51,2.35L13,21.44A3.6,3.6,0,0,0,10.2,25v6.5a1.93,1.93,0,0,0,1.93,1.93H39.58A1.92,1.92,0,0,0,41.51,31.55ZM15.45,28.71h0a2.24,2.24,0,0,1,0-4.47h0a2.24,2.24,0,0,1,0,4.47Zm10.41-7.33H17.8a1,1,0,0,1-1-1,1,1,0,0,1,.08-.39l1.35-3.2c.21-.5.6-1.27,1.46-1.28H32c.85,0,1.06.48,1.45,1.28L34.8,20a1,1,0,0,1-.91,1.38Zm10.25,7.49h0a2.24,2.24,0,0,1,0-4.47h0a2.24,2.24,0,0,1,0,4.47Z" />
    </g>
    <path d="M29.13,20.89a1.4,1.4,0,0,1,2.76,0h1.46a2.85,2.85,0,0,0-5.68,0Z" />
  </svg>

</div>
