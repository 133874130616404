import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignedDevice } from '../models/device';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends ApiService {

  domain = 'locations';

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  getDevices(locationId: string): Observable<AssignedDevice[]> {
    const url = this.host(this.domain, locationId, 'devices');
    return this.http.get<AssignedDevice[]>(url);
  }
}