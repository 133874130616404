<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path class="cls-1"
      d="M37.38,42H12.48a3.37,3.37,0,0,1-3.36-3.37V21a1.17,1.17,0,0,1,2.34,0V38.67a1,1,0,0,0,1,1h24.9a1,1,0,0,0,1-1V21a1.18,1.18,0,0,1,2.35,0V38.67A3.37,3.37,0,0,1,37.38,42Z" />
    <path class="cls-1"
      d="M42.5,25.05a1.16,1.16,0,0,1-.83-.34L27.11,10.14a3.08,3.08,0,0,0-4.35,0L8.19,24.71a1.17,1.17,0,1,1-1.66-1.66L21.1,8.48a5.43,5.43,0,0,1,7.66,0L43.33,23.05a1.17,1.17,0,0,1-.83,2Z" />
    <g id="car-fill-from-frontal-view">
      <path id="Path_26" data-name="Path 26" class="cls-1" d="M32.18,36.31v-1h-2.6v1a1.3,1.3,0,1,0,2.6,0Z" />
      <path id="Path_27" data-name="Path 27" class="cls-1" d="M20.27,36.31v-1H17.68v1a1.3,1.3,0,1,0,2.59.14Z" />
      <path id="Path_28" data-name="Path 28" class="cls-1"
        d="M33.56,33.16V29.58a2,2,0,0,0-1.57-2l-1.41-3.65a1.93,1.93,0,0,0-1.94-1.3H21.21a2,2,0,0,0-1.94,1.3l-1.41,3.65a2,2,0,0,0-1.55,2v3.58a1.05,1.05,0,0,0,1.06,1.06H32.49A1.06,1.06,0,0,0,33.56,33.16ZM19.2,31.6h0a1.23,1.23,0,0,1,0-2.46h0a1.23,1.23,0,0,1,0,2.46Zm5.73-4H20.49A.54.54,0,0,1,20,27a.52.52,0,0,1,0-.21L20.74,25a.93.93,0,0,1,.8-.71h6.77c.47,0,.58.27.8.71l.75,1.76a.56.56,0,0,1-.29.72.78.78,0,0,1-.22,0Zm5.65,4.13h0a1.23,1.23,0,0,1-1.2-1.26,1.22,1.22,0,0,1,1.2-1.2h0a1.22,1.22,0,0,1,1.18,1.27,1.24,1.24,0,0,1-1.19,1.19Z" />
    </g>
    <path class="cls-1" d="M26.73,27.29a.78.78,0,0,1,1.53,0h.8a1.57,1.57,0,0,0-3.13,0Z" />
  </svg>
</div>
