<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>
        .cls-1 {
          fill: #757575;
        }

      </style>
    </defs>
    <path class="cls-1"
      d="M12.4,3.86A8.14,8.14,0,1,0,20.53,12,8.15,8.15,0,0,0,12.4,3.86Zm0,14.24A6.11,6.11,0,0,1,6.29,12,6,6,0,0,1,7.42,8.47L15.93,17A6,6,0,0,1,12.4,18.1Zm5-2.57L8.86,7A6,6,0,0,1,12.4,5.9,6.11,6.11,0,0,1,18.5,12,6,6,0,0,1,17.37,15.53Z" />
  </svg>
</div>
