<div class="svg-icon" [ngClass]="{clickable: clickable, disabled: disabled}" [matTooltip]="toolTipText">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <defs>
      <style>
        .cls-1{fill:#000000;}
      </style>
    </defs>
    <g id="Group_30" data-name="Group 30">
      <g id="Group_10" data-name="Group 10">
        <path id="Path_24" class="cls-1" data-name="Path 24" d="M35.72,10H14.28a.65.65,0,0,0-.65.65V39a.65.65,0,0,0,.65.64h6.29a.64.64,0,0,0,.64-.64V33.89h7.58V39a.64.64,0,0,0,.64.64h6.29a.64.64,0,0,0,.65-.64V10.64A.65.65,0,0,0,35.72,10ZM20.81,29.07a.48.48,0,0,1-.49.48H17.43a.48.48,0,0,1-.49-.48h0V26.19a.49.49,0,0,1,.49-.49h2.89a.49.49,0,0,1,.49.49h0Zm0-6.1a.48.48,0,0,1-.49.48H17.43a.48.48,0,0,1-.49-.48h0V20.08a.48.48,0,0,1,.49-.48h2.89a.48.48,0,0,1,.49.48h0Zm0-6.11a.49.49,0,0,1-.49.49H17.43a.49.49,0,0,1-.49-.49h0V14a.49.49,0,0,1,.49-.49h2.89a.49.49,0,0,1,.49.49Zm6.12,12.21a.47.47,0,0,1-.48.48h-2.9a.47.47,0,0,1-.48-.48h0V26.19a.48.48,0,0,1,.48-.49h2.9a.48.48,0,0,1,.48.49h0Zm0-6.1a.47.47,0,0,1-.48.48h-2.9a.47.47,0,0,1-.48-.48h0V20.08a.47.47,0,0,1,.48-.48h2.9a.47.47,0,0,1,.48.48h0Zm0-6.11a.48.48,0,0,1-.48.49h-2.9a.48.48,0,0,1-.48-.49h0V14a.48.48,0,0,1,.48-.49h2.9a.48.48,0,0,1,.48.49Zm6.13,12.21a.48.48,0,0,1-.49.48H29.68a.48.48,0,0,1-.49-.48h0V26.19a.49.49,0,0,1,.49-.49h2.9a.49.49,0,0,1,.48.49h0Zm0-6.1a.48.48,0,0,1-.49.48H29.68a.48.48,0,0,1-.49-.48h0V20.08a.48.48,0,0,1,.49-.48h2.9a.48.48,0,0,1,.48.48h0Zm0-6.11a.49.49,0,0,1-.49.49H29.68a.49.49,0,0,1-.49-.49h0V14a.49.49,0,0,1,.49-.49h2.9a.49.49,0,0,1,.48.49h0Z" />
      </g>
      <path id="Path_25" class="cls-1" data-name="Path 25" d="M34.65,9.28V7.92a.64.64,0,0,0-.71-.56H15.7a.65.65,0,0,0-.71.56V9.28Z" />
    </g>
  </svg>  
</div>