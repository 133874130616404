import { DecimalPipe } from "@angular/common";
import { isNil } from "lodash";

export function _normalizeValue(value: string): string {
  if (isNil(value)) return '';

  return value.toLowerCase().replace(/\s/g, '');
}

export function getBasePath(): string {
  return `/${window.location.pathname.split('/').filter(x => !!x)[0]}`;
}

export function roundDecimal(value: number): number {
  return Math.round(value * 100) / 100;
}

export function toStrDecimal(value: number, decimalPipe: DecimalPipe): string | null {
  return decimalPipe.transform(value, '1.0-2');
}