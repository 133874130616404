import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { share, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpidSvgService {

  private observableCache$: { [key: string]: Observable<object> } = {};
  private cache$: { [key: string]: object } = {};

  constructor(private http: HttpClient) { }

  public getSvg = (src: any): Observable<object> => {

    if (this.cache$[src]) {
      return of(this.cache$[src]);
    } else if (this.observableCache$[src]) {
      return this.observableCache$[src];
    } else {
      this.observableCache$[src] = this.requestSvg(src);
    }

    return this.observableCache$[src];
  }

  private requestSvg = (src: any): Observable<object> => {
    return this.http.get(src, { responseType: 'text' }).pipe(map(data => this.mapSvg(src, data)), share());
  }

  private mapSvg = (src: any, data: any): object => {
    this.observableCache$[src] = new Observable();
    this.cache$[src] = new Object(data);
    return this.cache$[src];
  }
}

